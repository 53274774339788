import { useState } from 'react';
import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, MapPin, Clock, Users, Briefcase, X, Car, GraduationCap, Target, Brain } from 'lucide-react';
import { Card } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from '@/components/ui/dialog';

const jobs = [
  {
    id: 1,
    title: "Commercial(e) Spécialisé(e) avec Appétence pour l'IA",
    type: "CDI",
    location: "Paris 11e + Déplacements toute France",
    department: "Commercial",
    description: "AI-Forward est une startup innovante spécialisée dans l'intelligence artificielle. Nous aidons les entreprises à transformer leurs activités grâce à des solutions d'IA sur mesure. Notre équipe est dynamique, passionnée par les technologies de pointe et engagée dans une innovation constante.",
    responsibilities: [
      "Identifier et prospecter de nouvelles opportunités commerciales",
      "Établir et maintenir des relations solides avec les clients",
      "Comprendre les besoins spécifiques de chaque client",
      "Présenter nos services de manière convaincante",
      "Préparer et négocier les offres commerciales",
      "Assurer un suivi post-vente",
      "Participer à des événements professionnels",
      "Collaborer avec les équipes internes"
    ],
    requirements: [
      "3+ ans d'expérience en développement commercial B2B",
      "Bonne compréhension des enjeux de l'IA",
      "Excellentes capacités de communication",
      "Goût prononcé pour les défis",
      "Disponible pour des déplacements fréquents",
      "Diplôme en commerce, marketing ou équivalent"
    ],
    benefits: [
      "Environnement startup stimulant",
      "Perspectives d'évolution rapide",
      "Formation continue en IA",
      "Rémunération attractive (fixe + variable)",
      "Équipe passionnée et dynamique"
    ]
  },
  {
    id: 2,
    title: "Formateur / Futur Responsable de Formation",
    type: "CDI",
    location: "Paris 11e + Déplacements toute France",
    department: "Formation",
    description: "AI-Forward est une startup innovante spécialisée dans l'intelligence artificielle. Nous accompagnons les entreprises dans leur transformation numérique en les formant aux outils et usages de l'IA. Notre équipe est passionnée par les technologies de pointe et l'innovation pédagogique.",
    responsibilities: [
      "Créer des modules de formation innovants en IA",
      "Collaborer avec le CEO pour développer des parcours sur mesure",
      "Dispenser les formations en présentiel et en ligne",
      "Se déplacer régulièrement pour intervenir auprès des clients",
      "Participer à l'évolution de notre offre de formation",
      "Collecter et analyser les feedbacks des participants"
    ],
    requirements: [
      "Expérience significative en formation professionnelle",
      "Bonne connaissance des outils et usages de l'IA",
      "Excellentes compétences en communication",
      "Capacité à concevoir des supports pédagogiques",
      "Disponible pour des déplacements fréquents",
      "Créatif(ve), autonome et organisé(e)"
    ],
    benefits: [
      "Évolution vers un poste de Responsable de Formation",
      "Environnement startup stimulant",
      "Formation continue en IA et pédagogie",
      "Rémunération attractive (fixe + variable)",
      "Équipe passionnée et dynamique"
    ]
  }
];

export function Careers() {
  const [selectedJob, setSelectedJob] = useState<typeof jobs[0] | null>(null);

  const handleApply = (job: typeof jobs[0] | null) => {
    const subject = job 
      ? `Candidature ${job.title}`
      : 'Candidature spontanée';
    const mailtoLink = `mailto:recrutement@ai-forward.com?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-4xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Rejoignez l'Aventure
              </h1>
              <p className="text-xl text-gray-300">
                Participez à la révolution de l'IA en rejoignant une équipe passionnée et innovante
              </p>
            </motion.div>
          </div>

          {/* Jobs Grid */}
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {jobs.map((job) => (
              <motion.div
                key={job.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="relative group"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
                
                <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-6 h-full flex flex-col">
                  <div className="mb-6">
                    <h3 className="text-xl font-bold text-white mb-2">{job.title}</h3>
                    <div className="flex flex-wrap gap-4 text-sm text-gray-400">
                      <div className="flex items-center">
                        <Briefcase className="w-4 h-4 mr-1" />
                        {job.type}
                      </div>
                      <div className="flex items-center">
                        <MapPin className="w-4 h-4 mr-1" />
                        {job.location}
                      </div>
                      <div className="flex items-center">
                        <Users className="w-4 h-4 mr-1" />
                        {job.department}
                      </div>
                    </div>
                  </div>

                  <p className="text-gray-300 mb-6 flex-grow">
                    {job.description}
                  </p>

                  <div className="space-y-4 mb-6">
                    <div>
                      <h4 className="text-white font-semibold mb-2">Prérequis clés</h4>
                      <ul className="space-y-1">
                        {job.requirements.slice(0, 2).map((req, i) => (
                          <li key={i} className="text-sm text-gray-400">• {req}</li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <Button 
                    className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                    onClick={() => setSelectedJob(job)}
                  >
                    Voir le poste
                    <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
                  </Button>
                </Card>
              </motion.div>
            ))}
          </div>

          {/* Contact Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center mt-16"
          >
            <p className="text-gray-300 mb-6">
              Vous ne trouvez pas le poste qui vous correspond ? Envoyez-nous une candidature spontanée !
            </p>
            <Button
              variant="outline"
              className="text-white border-gray-700 hover:bg-white/5"
              onClick={() => handleApply(null)}
            >
              Candidature spontanée
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </motion.div>

          {/* Job Details Dialog */}
          <Dialog open={!!selectedJob} onOpenChange={() => setSelectedJob(null)}>
            <DialogContent className="bg-black/95 border-gray-800 text-white max-w-2xl max-h-[90vh] overflow-y-auto">
              <DialogHeader className="sticky top-0 bg-black/95 pt-6 pb-4 z-10">
                <DialogTitle className="text-2xl font-bold flex items-center justify-between">
                  {selectedJob?.title}
                  <DialogClose className="text-gray-400 hover:text-white">
                    <X className="w-5 h-5" />
                  </DialogClose>
                </DialogTitle>
                <div className="flex flex-wrap gap-4 text-sm text-gray-400 mt-2">
                  <div className="flex items-center">
                    <Briefcase className="w-4 h-4 mr-1" />
                    {selectedJob?.type}
                  </div>
                  <div className="flex items-center">
                    <MapPin className="w-4 h-4 mr-1" />
                    {selectedJob?.location}
                  </div>
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-1" />
                    {selectedJob?.department}
                  </div>
                </div>
              </DialogHeader>

              <div className="space-y-6">
                <DialogDescription className="text-gray-300">
                  {selectedJob?.description}
                </DialogDescription>

                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">Vos missions</h4>
                  <ul className="space-y-2">
                    {selectedJob?.responsibilities.map((resp, index) => (
                      <li key={index} className="flex items-start gap-2 text-gray-300">
                        <span className="text-[#685AFF]">•</span>
                        {resp}
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">Votre profil</h4>
                  <ul className="space-y-2">
                    {selectedJob?.requirements.map((req, index) => (
                      <li key={index} className="flex items-start gap-2 text-gray-300">
                        <span className="text-[#685AFF]">•</span>
                        {req}
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">Ce que nous offrons</h4>
                  <ul className="space-y-2">
                    {selectedJob?.benefits.map((benefit, index) => (
                      <li key={index} className="flex items-start gap-2 text-gray-300">
                        <span className="text-[#685AFF]">•</span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sticky bottom-0 bg-black/95 pt-4 pb-6">
                  <Button
                    className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                    onClick={() => {
                      setSelectedJob(null);
                      handleApply(selectedJob);
                    }}
                  >
                    Postuler maintenant
                    <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </section>
    </div>
  );
}