import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { UseCase } from './types';
import { cn } from '@/lib/utils';

interface UseCaseCardProps {
  useCase: UseCase;
}

export function UseCaseCard({ useCase }: UseCaseCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className="relative group"
    >
      {/* Glowing border effect */}
      <div className={cn(
        "absolute -inset-0.5 bg-gradient-to-r rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur",
        useCase.color
      )} />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8 h-full">
        {/* Icon */}
        <div className={cn(
          "w-12 h-12 rounded-lg bg-gradient-to-r p-3 mb-6",
          useCase.color
        )}>
          <useCase.icon className="w-full h-full text-white" />
        </div>

        {/* Title & Description */}
        <h3 className="text-xl font-bold text-white mb-4">{useCase.title}</h3>
        <p className="text-gray-300 mb-6">{useCase.description}</p>

        {/* Results */}
        <ul className="space-y-3">
          {useCase.results.map((result, index) => (
            <li key={index} className="flex items-center gap-3">
              <div className={cn(
                "w-1.5 h-1.5 rounded-full bg-gradient-to-r",
                useCase.color
              )} />
              <span className="text-gray-300 text-sm">{result}</span>
            </li>
          ))}
        </ul>
      </Card>
    </motion.div>
  );
}