import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Linkedin } from 'lucide-react';
import { TeamMember } from './types';

interface TeamMemberCardProps {
  member: TeamMember;
}

export function TeamMemberCard({ member }: TeamMemberCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className="relative group"
    >
      {/* Glowing border effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 overflow-hidden h-full">
        {/* Image */}
        <div className="aspect-square relative overflow-hidden">
          <img 
            src={member.image} 
            alt={member.name}
            className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          
          {/* Hover content */}
          <div className="absolute inset-0 flex flex-col justify-end p-6 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <p className="text-gray-300 text-sm mb-4">
              {member.bio}
            </p>
            {member.linkedin && (
              <a 
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-white hover:text-[#685AFF] transition-colors"
              >
                <Linkedin className="w-5 h-5 mr-2" />
                <span>LinkedIn</span>
              </a>
            )}
          </div>
        </div>

        {/* Info */}
        <div className="p-4">
          <h3 className="text-lg font-bold text-white mb-1">
            {member.name}
          </h3>
          <p className="text-[#685AFF] text-sm font-medium">
            {member.title}
          </p>
        </div>
      </Card>
    </motion.div>
  );
}