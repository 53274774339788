import { Partner, Testimonial } from './types';

export const partners: Partner[] = [
  {
    name: "Microsoft",
    description: "Partenaire stratégique pour l'intégration de solutions IA d'entreprise",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/512px-Microsoft_logo_%282012%29.svg.png",
    type: "strategic",
    expertise: ["Azure OpenAI", "Copilot", "Cloud Computing"],
    color: "from-[#685AFF] to-purple-600"
  },
  {
    name: "OpenAI",
    description: "Collaboration pour l'innovation en IA générative",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/OpenAI_Logo.svg/512px-OpenAI_Logo.svg.png",
    type: "strategic",
    expertise: ["GPT-4", "DALL-E", "Research"],
    color: "from-purple-600 to-pink-600"
  },
  {
    name: "IBM",
    description: "Partenaire pour les solutions d'IA en entreprise",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/512px-IBM_logo.svg.png",
    type: "strategic",
    expertise: ["Watson", "Enterprise AI", "Cloud"],
    color: "from-pink-600 to-red-600"
  },
  {
    name: "Anthropic",
    description: "Partenaire pour l'IA éthique et responsable",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Anthropic_logo.svg/512px-Anthropic_logo.svg.png",
    type: "technology",
    expertise: ["Claude", "AI Safety", "Ethics"],
    color: "from-red-600 to-orange-600"
  },
  {
    name: "HuggingFace",
    description: "Collaboration sur les modèles open source",
    logo: "https://huggingface.co/front/assets/huggingface_logo.svg",
    type: "technology",
    expertise: ["Open Source", "NLP", "Models"],
    color: "from-orange-600 to-yellow-600"
  },
  {
    name: "École Polytechnique",
    description: "Partenariat académique pour la recherche en IA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Logo_École_polytechnique.svg/512px-Logo_École_polytechnique.svg.png",
    type: "academic",
    expertise: ["Research", "Education", "Innovation"],
    color: "from-yellow-600 to-[#685AFF]"
  }
];

export const testimonials: Testimonial[] = [
  {
    content: "Notre partenariat avec Forward a permis d'accélérer significativement notre transformation digitale et l'adoption de l'IA.",
    author: "Sophie M.",
    position: "Directrice Innovation",
    company: "",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=800&h=800"
  },
  {
    content: "L'expertise de Forward en formation IA est inégalée. Leur approche pédagogique est parfaitement adaptée aux besoins des entreprises.",
    author: "Thomas D.",
    position: "CTO",
    company: "",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=800&h=800"
  },
  {
    content: "Un partenaire de confiance qui comprend vraiment les enjeux de l'IA générative en entreprise.",
    author: "Marie L.",
    position: "CEO",
    company: "",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=800&h=800"
  }
];