import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';

export function ReglementInterieur() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-12 text-white text-center">
              Règlement intérieur AI-Forward Formations
            </h1>

            <div className="prose prose-invert max-w-none">
              <h2>1. Introduction</h2>
              <p>Présentation de l'organisme de formation. AI Forward, établie à Maast et Violaine et fondée en 2023 par Arnaud Cliquennois, est une société française spécialisée dans la formation continue d'adultes, avec un accent particulier sur l'intelligence artificielle (IA). Avec un capital de 5 000 euros, elle vise à fournir des formations de qualité pour développer les compétences en IA des adultes. Pour des détails plus approfondis, il est recommandé de consulter leur site officiel ou de les contacter directement.</p>
              <p>En mettant l'accent sur l'innovation et la technologie de pointe, AI Forward se positionne comme un leader dans l'enseignement de l'intelligence artificielle, visant à équiper ses participants des compétences nécessaires pour exceller dans ce domaine en rapide évolution. Engagée à combler le fossé entre les avancées technologiques et le développement professionnel, AI Forward offre un environnement d'apprentissage enrichissant qui prépare les individus à relever les défis futurs de l'IA avec confiance et compétence.</p>
              <p><strong>Objectif du règlement intérieur (RI) :</strong> Le règlement intérieur vise principalement à instaurer un cadre structuré et sécurisé pour tous les participants. Il établit les règles essentielles de comportement, d'assiduité, et de respect mutuel au sein de l'organisme de formation, tout en définissant les procédures en cas de manquement. Ce document assure également le respect des normes d'hygiène et de sécurité, et précise les modalités de suivi pédagogique et d'évaluation des formations. L'objectif est de favoriser un environnement d'apprentissage optimal et professionnel pour le développement des compétences des stagiaires.</p>

              <h2>2. Champ d'application</h2>
              <p>Le champ d'application du règlement intérieur couvre toutes les personnes inscrites aux formations proposées par l'organisme, incluant les stagiaires, les formateurs, et le personnel administratif. Ce document s'applique durant toutes les phases de la formation, que ce soit en présentiel, à distance, ou lors des activités annexes organisées par l'organisme. Il vise à garantir un environnement d'apprentissage respectueux, sécurisé, et propice au développement professionnel et personnel de chaque individu.</p>

              <h2>3. Accès à la formation</h2>
              <p>AI Forward accueille les professionnels, sans exigence de niveau d'études, incluant salariés, professions libérales, et administrations.</p>
              <p>La procédure d'inscription repose sur un questionnaire préalable pour personnaliser la formation. Les compétences minimales requises sont l'utilisation d'outils de microinformatique, ainsi que l'accès à un ordinateur et internet. Des aménagements spécifiques pour les personnes en situation de handicap sont envisageables sur demande, favorisant ainsi l'accès et la participation complète à nos formations, y compris à distance si nécessaire.</p>

              <h2>4. Déroulement de la formation</h2>
              <p>AI Forward privilégie les formations en présentiel, adaptant le format à distance en cas de nécessité spécifique. Les sessions se déroulent sur 7 heures quotidiennes, incluant des pauses et une pause déjeuner.</p>
              <p>L'usage de Digiforma permet le suivi des stagiaires, avec des évaluations avant et après formation, alignées aux critères Qualiopi. Les retours et évaluations des stagiaires sont consignés dans un tableau de bord détaillé, facilitant l'identification des besoins d'amélioration et permettant l'implémentation d'actions correctives spécifiques sur divers aspects de la formation tels que les moyens techniques, les outils pédagogiques, la durée et l'intensité de la formation, ou encore les compétences des formateurs, dans le but d'améliorer continuellement la qualité de l'enseignement.</p>
              <p>Les participants sont requis d'apporter leur ordinateur pour les exercices, avec des licences fournies par AI Forward. Les locaux doivent être équipés pour soutenir l'apprentissage numérique.</p>

              <h2>5. Assiduité et ponctualité</h2>
              <p>Les stagiaires doivent adhérer strictement aux horaires et aux exigences de participation. En cas d'absence ou de retard, une justification rapide est requise. Un suivi est effectué pour assurer le respect de ces normes, crucial pour l'efficacité des formations et le succès des participants. Les écarts à ces règles peuvent entraîner des actions correctives telles que des avertissements, des exclusions temporaires de sessions, ou, dans des situations sévères, l'exclusion du programme.</p>

              <h2>6. Hygiène et sécurité</h2>
              <p>Pour toute formation en présentiel, que celle-ci ait lieu dans les locaux d'un client ou sur un site loué, l'ensemble des participants et formateurs est tenu de respecter scrupuleusement les normes et règles d'hygiène et de sécurité en vigueur sur le lieu de formation. Cette exigence comprend le respect des protocoles de sécurité spécifiques, l'utilisation adéquate des installations et le suivi des procédures d'urgence définies, afin d'assurer un environnement d'apprentissage sûr pour tous. Le manquement à ces règles entraînera des actions correctives adaptées.</p>

              <h2>7. Règles de vie collective</h2>
              <p>Les participants et formateurs sont tenus de maintenir un comportement respectueux, soutenant un environnement d'apprentissage inclusif et sans discrimination. L'utilisation responsable des équipements et des espaces est exigée. La communication doit être constructive pour résoudre tout conflit. L'enregistrement ou la capture vidéo des sessions de formation est formellement interdit afin de garantir la protection des données personnelles et de préserver la propriété intellectuelle de l'organisme de formation. Les supports pédagogiques seront fournis exclusivement via l'accès personnel à l'outil Digiforma, assurant ainsi la distribution sécurisée et le respect de la confidentialité des matériaux de formation.</p>

              <h2>8. Dispositions relatives à la discipline</h2>
              <p>Dans notre organisme de formation, le respect des règles et procédures est essentiel pour garantir un environnement d'apprentissage optimal. En cas de manquement, une procédure disciplinaire juste et transparente est appliquée, respectant les droits de défense de chaque stagiaire. Cette approche vise à maintenir l'ordre et la discipline, avec des mesures pouvant aller de l'avertissement à l'exclusion temporaire ou définitive, selon la gravité de l'infraction. L'objectif est d'assurer le bien-être et la réussite de tous les participants.</p>

              <h2>9. Réclamations et médiation</h2>
              <p>L'organisme de formation s'engage à mettre en œuvre une procédure transparente et efficace pour le recueil et le traitement des réclamations. Toute partie prenante (stagiaires, clients, partenaires) souhaitant exprimer une réclamation est invitée à le faire via un formulaire dédié, disponible sur demande ou sur notre site internet. La réclamation doit être formulée par écrit et décrire clairement l'objet du mécontentement.</p>
              
              <h3>Processus de réclamation :</h3>
              <ol>
                <li>Réception de la réclamation : Toutes les réclamations sont enregistrées et centralisées. Un accusé de réception est envoyé au réclamant dans les meilleurs délais.</li>
                <li>Examen et traitement : La réclamation est examinée de manière équitable et objective pour identifier les actions correctives nécessaires.</li>
                <li>Réponse : Une réponse détaillée est fournie au réclamant, expliquant les conclusions de l'examen et les mesures prises en réponse.</li>
                <li>Suivi : Des actions correctives sont mises en œuvre pour prévenir la récurrence du problème. Un suivi des réclamations est assuré pour améliorer continuellement nos services.</li>
              </ol>
              
              <p>En cas de désaccord persistant suite à la réponse apportée par l'organisme, les parties prenantes ont la possibilité de recourir à un médiateur externe. Ce dernier, choisi pour son impartialité et son expertise, facilitera la recherche d'une solution amiable. La médiation est préférée aux voies judiciaires pour résoudre les litiges de manière constructive.</p>

              <h2>10. Protection des données personnelles</h2>
              <p>Conformément au Règlement Général sur la Protection des Données (RGPD) et à la législation en vigueur, notre organisme de formation s'engage à la protection des données personnelles de toutes les parties prenantes (stagiaires, formateurs, personnel administratif, etc.). Cette section précise les mesures prises pour garantir la sécurité et la confidentialité des données personnelles collectées dans le cadre des activités de formation.</p>
              
              <h3>Principes généraux :</h3>
              <ol>
                <li>Collecte et utilisation : Les données personnelles sont collectées de manière équitable et transparente, uniquement pour des finalités définies, explicites et légitimes. Elles sont traitées de manière à garantir une sécurité adéquate.</li>
                <li>Conservation : Les données sont conservées dans des conditions sécurisées et pour une durée n'excédant pas celle nécessaire aux finalités pour lesquelles elles sont traitées.</li>
                <li>Droits des personnes concernées : Chaque personne dispose d'un droit d'accès, de rectification, d'effacement et de portabilité de ses données, ainsi que du droit de s'opposer à leur traitement ou d'en demander la limitation.</li>
              </ol>
              
              <h3>Mesures de sécurité :</h3>
              <p>Des mesures techniques et organisationnelles appropriées sont mises en œuvre pour protéger les données personnelles contre la perte, l'altération, la divulgation non autorisée ou l'accès accidentel. Cela inclut la formation du personnel à la protection des données et l'utilisation de solutions technologiques sécurisées.</p>
              
              <h3>Notification en cas de violation :</h3>
              <p>En cas de violation de données personnelles susceptible d'engendrer un risque élevé pour les droits et libertés des personnes concernées, l'organisme s'engage à notifier cette violation à l'autorité compétente et, lorsque cela est requis par la législation, aux personnes affectées.</p>
              
              <h3>Responsable de la protection des données :</h3>
              <p>L'organisme a désigné un responsable de la protection des données (DPO) chargé de veiller au respect de la réglementation en matière de protection des données. Ses coordonnées sont disponibles pour toute question ou demande relative à la protection des données personnelles.</p>

              <h2>11. Modification du règlement intérieur</h2>
              <p>Notre organisme de formation s'engage à maintenir son règlement intérieur à jour afin de répondre aux évolutions législatives, réglementaires, et aux besoins de notre communauté d'apprenants et d'intervenants. Le processus de révision est conçu pour être transparent et inclusif, permettant une amélioration continue de nos pratiques et de notre environnement d'apprentissage.</p>
              
              <h3>Procédure de révision :</h3>
              <ol>
                <li>Évaluation périodique : Le règlement intérieur est évalué annuellement pour identifier les besoins de modification ou de mise à jour.</li>
                <li>Consultation : Les suggestions de révision peuvent être soumises par les formateurs, les apprenants, ou le personnel administratif à tout moment. Une période de consultation est ouverte avant toute modification majeure pour recueillir les avis et suggestions.</li>
                <li>Mise à jour : Les modifications sont effectuées par la direction de l'organisme, en tenant compte des retours recueillis pendant la période de consultation.</li>
                <li>Communication : Toutes les modifications apportées au règlement intérieur sont communiquées à l'ensemble des parties prenantes et mises à disposition sur notre plateforme Digiforma.</li>
              </ol>
              
              <h3>Accès et engagement :</h3>
              <p>L'accès au règlement intérieur révisé est garanti à tous les intervenants via la plateforme Digiforma, assurant une transparence totale et un engagement partagé à respecter les normes et valeurs de notre organisme. En cas de questions concernant les révisions, les participants sont encouragés à contacter l'administration de l'organisme de formation.</p>

              <h2>Conclusion</h2>
              <p>En conclusion, notre organisme de formation est dédié à fournir un environnement d'apprentissage sécurisé, inclusif, et propice au développement professionnel de chacun. Le règlement intérieur présenté ici reflète notre engagement envers l'excellence pédagogique et le respect mutuel entre tous les participants à nos programmes de formation.</p>
              <p>La mise en application de ce règlement intérieur sera effective à partir du 1er janvier 2024. Dès cette date, tous les apprenants, formateurs, et le personnel administratif seront tenus de le respecter pour garantir une expérience d'apprentissage de la plus haute qualité. Nous encourageons chacun à le consulter régulièrement via notre plateforme Digiforma pour se familiariser avec les dispositions qu'il contient et s'assurer de leur bonne application.</p>
              <p>Nous restons ouverts à toute suggestion permettant d'améliorer ce document et, par extension, la qualité de nos formations. L'engagement de chacun envers le respect de ce règlement intérieur contribuera significativement à la réussite de tous.</p>
              <p>Pour toute question ou demande d'éclaircissement, n'hésitez pas à contacter l'administration de notre organisme. Ensemble, faisons de cet environnement d'apprentissage un lieu où chacun peut se développer professionnellement dans les meilleures conditions.</p>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}