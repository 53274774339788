import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { Clock, Users, CheckCircle2, Target, Book, Award, Calendar, Accessibility, ArrowRight } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const courseContent = [
  {
    title: 'Introduction à l\'IA Générative pour le Commerce',
    content: 'Comprendre les fondamentaux et les applications spécifiques au secteur commercial'
  },
  {
    title: 'Outils d\'IA pour l\'Optimisation des Ventes',
    content: 'Maîtrise des outils d\'IA pour améliorer les performances commerciales'
  },
  {
    title: 'Automatisation et Personnalisation Client',
    content: 'Techniques avancées pour automatiser et personnaliser l\'expérience client'
  },
  {
    title: 'Stratégies d\'Implementation et Cas Pratiques',
    content: 'Application concrète dans des scénarios commerciaux réels'
  }
];

const objectives = [
  'Maîtriser les outils d\'IA générative pour le commerce et la vente',
  'Optimiser les processus commerciaux grâce à l\'IA',
  'Personnaliser l\'expérience client à grande échelle',
  'Développer des stratégies commerciales basées sur l\'IA'
];

const team = [
  {
    name: 'Arnaud Cliquennois',
    role: 'Expert en IA Générative pour le Commerce',
    description: 'Pionnier de la formation à l\'intelligence artificielle générative en entreprise, Arnaud guide les professionnels à travers la révolution IA avec une expertise particulière dans le secteur commercial.'
  }
];

export function FormationIACommerce() {
  const handleCalendlyClick = () => {
    window.open('https://calendly.com/arnaud-c-lgfv/decouverte-ai-forward', '_blank');
  };

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-4xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                L'intelligence artificielle générative au service du commerce
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                Formation IAG - Niveau 2
              </p>
              
              <div className="flex flex-wrap justify-center gap-6 mb-8">
                <div className="flex items-center text-gray-300">
                  <Users className="w-5 h-5 mr-2" />
                  <span>Formation présentielle</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <Clock className="w-5 h-5 mr-2" />
                  <span>Durée : 7 heures (1 jour)</span>
                </div>
                <div className="flex items-center text-[#685AFF]">
                  <span>Accessible</span>
                </div>
              </div>

              <div className="flex justify-center">
                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
                  <div className="flex flex-col items-center">
                    <span className="text-2xl font-bold text-white">3 900€ HT</span>
                    <span className="text-sm text-gray-400 mt-1">*pour un groupe de 8 personnes soit 487€ par apprenant</span>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {/* Left Column */}
            <div className="lg:col-span-2 space-y-8">
              {/* Program Description */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2 className="text-2xl font-bold text-white mb-4">Programme de la Formation</h2>
                <p className="text-gray-300">
                  Cette formation avancée est spécialement conçue pour les professionnels du commerce et de la vente souhaitant exploiter pleinement le potentiel de l'IA générative dans leur secteur.

                  Au cours de cette formation, vous découvrirez comment :

                  - Optimiser vos processus de vente grâce à l'IA
                  - Personnaliser l'expérience client à grande échelle
                  - Automatiser les tâches répétitives
                  - Développer des stratégies commerciales innovantes
                  - Analyser et prédire les comportements d'achat
                </p>
                <p className="text-gray-300 mt-4">
                  La formation combine théorie et pratique avec des cas d'usage réels du secteur commercial, permettant une application immédiate des connaissances acquises.
                </p>
              </motion.div>

              {/* Course Content */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2 className="text-2xl font-bold text-white mb-4">Contenu de la Formation</h2>
                <Accordion type="single" collapsible className="space-y-2">
                  {courseContent.map((item, index) => (
                    <AccordionItem key={index} value={`item-${index}`}>
                      <AccordionTrigger className="text-white hover:text-[#685AFF]">
                        {item.title}
                      </AccordionTrigger>
                      <AccordionContent className="text-gray-300">
                        {item.content}
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </motion.div>

              {/* Beneficiaries Profile */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-4 mb-6">
                  <div className="w-12 h-12 rounded-lg bg-[#685AFF]/20 flex items-center justify-center">
                    <Users className="w-6 h-6 text-[#685AFF]" />
                  </div>
                  <h2 className="text-2xl font-bold text-white">Profil des Bénéficiaires</h2>
                </div>

                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-3">Pour qui</h3>
                    <ul className="space-y-2 text-gray-300">
                      <li>Commerciaux et responsables des ventes</li>
                      <li>Directeurs commerciaux et business developers</li>
                      <li>Professionnels du marketing et de la relation client</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-lg font-semibold text-white mb-3">Prérequis</h3>
                    <p className="text-gray-300">
                      Connaissances de base en IA générative ou avoir suivi le Niveau 1
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Execution Monitoring */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2 className="text-2xl font-bold text-white mb-4">Suivi de l'Exécution et Évaluation des Résultats</h2>
                <p className="text-gray-300">
                  Les apprenants ont accès à un extranet qui leur permet de :
                  - Prendre connaissance des date, lieu, programme détaillé et intervenant de la formation
                  - Signer leurs feuilles de présence
                  - Renseigner le questionnaire préformation
                  - Avoir accès en fin de formation à un support de cours
                  - Renseigner les questionnaires d'évaluation
                  - Recevoir leur attestation de formation
                </p>
              </motion.div>

              {/* Technical Resources */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2 className="text-2xl font-bold text-white mb-4">Ressources Techniques et Pédagogiques</h2>
                <div className="space-y-4 text-gray-300">
                  <p>
                    Équipements et Logiciels Nécessaires :
                    - Ordinateurs équipés d'une connexion internet
                    - Accès aux plateformes d'IA spécialisées pour le commerce
                    - Environnement de travail adapté aux exercices pratiques
                  </p>
                  <p>
                    Supports Fournis :
                    - Licences temporaires pour les outils d'IA
                    - Documentation complète et guides pratiques
                    - Accès aux ressources en ligne post-formation
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Right Column - Sidebar */}
            <div className="space-y-8">
              {/* Objectives */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2 className="text-2xl font-bold text-white mb-4">Objectifs</h2>
                <ul className="space-y-4">
                  {objectives.map((objective, index) => (
                    <li key={index} className="flex items-start gap-3">
                      <CheckCircle2 className="w-5 h-5 text-[#685AFF] flex-shrink-0 mt-1" />
                      <span className="text-gray-300">{objective}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>

              {/* Key Information */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800 space-y-6"
              >
                <div>
                  <h3 className="text-lg font-semibold text-white mb-3">Capacité d'Accueil</h3>
                  <p className="text-gray-300 flex items-center">
                    <Users className="w-5 h-5 mr-2 text-[#685AFF]" />
                    Entre 4 et 8 apprenants
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-white mb-3">Délai d'Accès</h3>
                  <p className="text-gray-300 flex items-center">
                    <Calendar className="w-5 h-5 mr-2 text-[#685AFF]" />
                    3 semaines
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-white mb-3">Accessibilité</h3>
                  <p className="text-gray-300 flex items-center">
                    <Accessibility className="w-5 h-5 mr-2 text-[#685AFF]" />
                    Les locaux fournis par le client doivent être équipés pour soutenir l'apprentissage numérique.
                  </p>
                </div>

                <Button 
                  className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                  size="lg"
                  onClick={handleCalendlyClick}
                >
                  <span>Réserver un créneau</span>
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </Button>
              </motion.div>

              {/* Satisfaction Rate */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h3 className="text-lg font-semibold text-white mb-4">Satisfaction</h3>
                <div className="flex items-center gap-2">
                  <div className="flex-1 h-2 bg-gray-700 rounded-full overflow-hidden">
                    <div className="h-full bg-[#685AFF] w-[97%]" />
                  </div>
                  <span className="text-white font-semibold">9,7/10</span>
                </div>
                <p className="text-gray-400 text-sm mt-2">(45 avis)</p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}