import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCkLlhlgtW6VlKg0hXSNaAIY_-YoXM1ZtA",
  authDomain: "admin-aif.firebaseapp.com",
  projectId: "admin-aif",
  storageBucket: "admin-aif.firebasestorage.app",
  messagingSenderId: "743658287371",
  appId: "1:743658287371:web:4ae26fcf35aba75b02f20e",
  measurementId: "G-HW2XKBR5Y0"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);