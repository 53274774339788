import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';
import { testimonials } from './data';

export function TestimonialSlider() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {testimonials.map((testimonial, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          whileHover={{ y: -5 }}
          className="relative group"
        >
          {/* Glowing border effect */}
          <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
          
          {/* Card content */}
          <div className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8 rounded-lg">
            {/* Quote icon */}
            <div className="absolute -top-4 -left-4 w-8 h-8 bg-[#685AFF] rounded-full flex items-center justify-center">
              <Quote className="w-4 h-4 text-white" />
            </div>

            {/* Content */}
            <p className="text-gray-300 mb-8 italic">"{testimonial.content}"</p>

            {/* Author */}
            <div>
              <h4 className="text-white font-semibold">{testimonial.author}</h4>
              <p className="text-gray-400 text-sm">{testimonial.position}</p>
              <p className="text-[#685AFF] text-sm">{testimonial.company}</p>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}