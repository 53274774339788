import { UseCase, Testimonial } from './types';
import { Mail, Users, Brain, LineChart, ShieldCheck, FileText } from 'lucide-react';

export const useCases: UseCase[] = [
  {
    title: "Marketing et Vente",
    description: "Automatisez vos campagnes et personnalisez vos offres grâce à l'IA",
    icon: Mail,
    results: [
      "Réduction de 30% du temps de gestion des emails",
      "Augmentation de 25% du taux de conversion",
      "Personnalisation client améliorée"
    ],
    color: "from-[#685AFF] to-purple-600"
  },
  {
    title: "Ressources Humaines",
    description: "Optimisez vos processus RH avec des solutions IA innovantes",
    icon: Users,
    results: [
      "Temps de recrutement réduit de 40%",
      "Satisfaction employés améliorée de 35%",
      "Automatisation des tâches administratives"
    ],
    color: "from-purple-600 to-pink-600"
  },
  {
    title: "Service Client",
    description: "Améliorez votre support client avec l'IA conversationnelle",
    icon: Brain,
    results: [
      "Support client 24/7",
      "Réponse instantanée aux questions fréquentes",
      "Satisfaction client augmentée de 45%"
    ],
    color: "from-pink-600 to-red-600"
  },
  {
    title: "Analyse et Reporting",
    description: "Prenez des décisions éclairées grâce à l'analyse prédictive",
    icon: LineChart,
    results: [
      "Prévisions précises à 90%",
      "Réduction des coûts de 20%",
      "Optimisation des processus"
    ],
    color: "from-red-600 to-orange-600"
  },
  {
    title: "Sécurité et Conformité",
    description: "Renforcez votre sécurité avec l'IA",
    icon: ShieldCheck,
    results: [
      "Détection des fraudes améliorée de 60%",
      "Conformité RGPD automatisée",
      "Risques réduits de 40%"
    ],
    color: "from-orange-600 to-yellow-600"
  },
  {
    title: "Gestion Documentaire",
    description: "Automatisez le traitement de vos documents",
    icon: FileText,
    results: [
      "Temps de traitement réduit de 70%",
      "Précision améliorée de 95%",
      "Classement automatique"
    ],
    color: "from-yellow-600 to-[#685AFF]"
  }
];

export const testimonials: Testimonial[] = [
  {
    content: "Grâce à AI Forward, nous avons réduit nos coûts de 20% en 6 mois tout en améliorant notre productivité.",
    author: "Sophie M.",
    position: "Directrice des Opérations",
    company: ""
  },
  {
    content: "L'accompagnement d'AI Forward nous a permis de moderniser nos processus et de rester compétitifs.",
    author: "Jean D.",
    position: "CEO",
    company: ""
  },
  {
    content: "Une expertise remarquable et un accompagnement sur mesure qui ont transformé notre approche de l'IA.",
    author: "Marie L.",
    position: "DRH",
    company: ""
  }
];