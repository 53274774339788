import { motion } from 'framer-motion';
import { Brain, Database, Code2, Users2, Target, Rocket } from 'lucide-react';
import { Service, ProcessStep } from './types';

const services = [
  {
    title: "Formation & Montée en Compétences",
    description: "Programmes de formation personnalisés pour vos équipes",
    icon: Brain,
    features: [
      "Formation sur mesure adaptée à vos besoins",
      "Ateliers pratiques et cas d'usage réels",
      "Suivi et évaluation des progrès",
      "Support continu post-formation"
    ],
    color: "from-[#685AFF] to-purple-600"
  },
  {
    title: "Stratégie Data & IA",
    description: "Optimisation de vos données pour l'IA générative",
    icon: Database,
    features: [
      "Audit de vos données existantes",
      "Stratégie d'acquisition et de qualité",
      "Gouvernance des données",
      "Intégration avec l'IA générative"
    ],
    color: "from-purple-600 to-pink-600"
  },
  {
    title: "Développement Solutions IA",
    description: "Création de solutions IA sur mesure",
    icon: Code2,
    features: [
      "Développement d'applications IA",
      "Intégration avec vos systèmes",
      "Tests et optimisation",
      "Maintenance et évolution"
    ],
    color: "from-pink-600 to-red-600"
  }
];

export function Services() {
  return (
    <section className="w-full py-12">
      <div className="max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            Comment pouvons-nous aider votre entreprise ?
          </h2>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.a
              key={service.title}
              href="#accompagnement"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.02 }}
              className="relative group cursor-pointer"
            >
              {/* Glowing border effect */}
              <div className={`absolute -inset-0.5 bg-gradient-to-r ${service.color} rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur-sm`} />
              
              {/* Card content */}
              <div className="relative flex flex-col h-full bg-black/20 backdrop-blur-sm border border-gray-800/50 p-8 rounded-lg overflow-hidden">
                {/* Icon */}
                <div className={`w-12 h-12 rounded-lg bg-gradient-to-r ${service.color} p-3 flex items-center justify-center mb-6`}>
                  <service.icon className="w-6 h-6 text-white" />
                </div>
                
                {/* Title */}
                <h3 className="text-xl font-bold text-white mb-4 group-hover:text-[#685AFF] transition-colors">
                  {service.title}
                </h3>
                
                {/* Description */}
                <p className="text-gray-300 text-sm leading-relaxed">
                  {service.description}
                </p>

                {/* Hover effect background */}
                <div className="absolute -bottom-1 -right-1 w-32 h-32 bg-gradient-to-r from-transparent via-[#685AFF]/10 to-[#685AFF]/20 rounded-full blur-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
}