import { Brain, Database, Code2, Users2, Target, Rocket } from 'lucide-react';
import { Service, ProcessStep } from './types';

export const services: Service[] = [
  {
    title: "Formation & Montée en Compétences",
    description: "Programmes de formation personnalisés pour vos équipes",
    icon: Brain,
    features: [
      "Formation sur mesure adaptée à vos besoins",
      "Ateliers pratiques et cas d'usage réels",
      "Suivi et évaluation des progrès",
      "Support continu post-formation"
    ],
    color: "from-[#685AFF] to-purple-600"
  },
  {
    title: "Stratégie Data & IA",
    description: "Optimisation de vos données pour l'IA générative",
    icon: Database,
    features: [
      "Audit de vos données existantes",
      "Stratégie d'acquisition et de qualité",
      "Gouvernance des données",
      "Intégration avec l'IA générative"
    ],
    color: "from-purple-600 to-pink-600"
  },
  {
    title: "Développement Solutions IA",
    description: "Création de solutions IA sur mesure",
    icon: Code2,
    features: [
      "Développement d'applications IA",
      "Intégration avec vos systèmes",
      "Tests et optimisation",
      "Maintenance et évolution"
    ],
    color: "from-pink-600 to-red-600"
  }
];

export const processSteps: ProcessStep[] = [
  {
    title: "Analyse des Besoins",
    description: "Évaluation approfondie de vos objectifs et challenges",
    icon: Target
  },
  {
    title: "Stratégie Personnalisée",
    description: "Élaboration d'un plan d'action sur mesure",
    icon: Users2
  },
  {
    title: "Implémentation",
    description: "Mise en place progressive des solutions",
    icon: Code2
  },
  {
    title: "Suivi & Optimisation",
    description: "Accompagnement continu et ajustements",
    icon: Rocket
  }
];