import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import { UseCaseCard } from './UseCaseCard';
import { ProcessTimeline } from './ProcessTimeline';
import { TestimonialSlider } from './TestimonialSlider';
import { useCases } from './data';

export function UseCases() {
  const handleCalendlyClick = () => {
    window.open('https://calendly.com/arnaud-c-lgfv/decouverte-ai-forward', '_blank');
  };

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Process Timeline */}
          <div className="max-w-5xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl font-bold text-white mb-4">
                Un accompagnement clé en main pour intégrer l'IA
              </h2>
              <p className="text-xl text-gray-300">
                Notre processus éprouvé pour une intégration réussie
              </p>
            </motion.div>

            <ProcessTimeline />
          </div>

          {/* Use Cases Grid */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl font-bold text-white mb-4">
                Cas d'Usage Concrets
              </h2>
              <p className="text-xl text-gray-300">
                Découvrez comment l'IA peut transformer votre secteur d'activité
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {useCases.map((useCase, index) => (
                <UseCaseCard key={index} useCase={useCase} />
              ))}
            </div>
          </div>

          {/* Testimonials */}
          <div className="max-w-4xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl font-bold text-white mb-4">
                Ils nous font confiance
              </h2>
              <p className="text-xl text-gray-300">
                Découvrez les retours d'expérience de nos clients
              </p>
            </motion.div>

            <TestimonialSlider />
          </div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center bg-gradient-to-r from-[#685AFF]/10 to-purple-600/10 backdrop-blur-sm rounded-xl p-8 border border-gray-800"
          >
            <h2 className="text-3xl font-bold text-white mb-4">
              Prêt à intégrer l'IA dans votre quotidien ?
            </h2>
            <p className="text-gray-300 mb-8 text-lg">
              Contactez-nous pour un audit gratuit et démarrez votre transformation digitale.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button 
                className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                size="lg"
                onClick={handleCalendlyClick}
              >
                Planifiez un rendez-vous
                <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
              </Button>
              <Button 
                variant="outline"
                size="lg"
                className="text-white border-gray-700 hover:bg-white/5"
                onClick={() => window.location.hash = 'contact'}
              >
                Contactez-nous
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}