import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, ArrowUpRight, Mail, Newspaper } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { featuredPosts } from './data';

export function Blog() {
  const handleContactClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.hash = 'contact';
  };

  return (
    <div className="min-h-screen bg-black relative">
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-3xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Actualités
              </h1>
              <p className="text-xl text-gray-300">
                Découvrez nos dernières actualités et notre présence dans les médias
              </p>
            </motion.div>
          </div>

          {/* Main Content */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 max-w-7xl mx-auto">
            {/* Left Column - Company News */}
            <div className="space-y-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="mb-8"
              >
                <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-2">
                  <Newspaper className="w-6 h-6 text-[#685AFF]" />
                  Actualités AI-Forward
                </h2>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-black/50 backdrop-blur-sm border border-gray-800 rounded-lg p-6"
              >
                <p className="text-gray-300 text-center">
                  Restez à l'écoute pour nos prochaines actualités !
                </p>
              </motion.div>
            </div>

            {/* Right Column - Press */}
            <div className="space-y-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="mb-8"
              >
                <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-2">
                  <Mail className="w-6 h-6 text-[#685AFF]" />
                  Presse
                </h2>
              </motion.div>

              {/* Press Article */}
              <motion.a
                href="https://www.lejournaldesentreprises.com/article/il-est-vital-que-les-pme-francaises-semparent-de-lia-generative-pour-rester-competitives-2101496"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                whileHover={{ y: -5 }}
                className="relative group block"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
                
                <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-6">
                  <div className="flex items-center justify-between mb-4">
                    <img 
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn18zbwus6H9dh7gWoXAvA2FghkpiyIAbQVA&s"
                      alt="Le Journal des Entreprises"
                      className="h-8 w-auto"
                    />
                    <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-white transition-colors" />
                  </div>

                  <h3 className="text-xl font-bold text-white mb-4 group-hover:text-[#685AFF] transition-colors">
                    "Il est vital que les PME françaises s'emparent de l'IA générative pour rester compétitives"
                  </h3>

                  <p className="text-gray-300 mb-4">
                    Interview d'Arnaud Cliquennois sur l'importance de l'IA générative pour les PME françaises
                  </p>

                  <p className="text-sm text-gray-400">
                    23 Mars 2024
                  </p>
                </Card>
              </motion.a>

              {/* Press Contact */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-gradient-to-r from-[#685AFF]/10 to-purple-600/10 backdrop-blur-sm rounded-xl p-6 border border-gray-800 mt-12"
              >
                <Mail className="w-12 h-12 text-[#685AFF] mx-auto mb-6" />
                <h2 className="text-xl font-bold text-white mb-4 text-center">
                  Contact Presse
                </h2>
                <p className="text-gray-300 mb-6 text-center">
                  Pour toute demande presse, interview ou information complémentaire, notre équipe est à votre disposition.
                </p>
                <Button 
                  className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                  onClick={handleContactClick}
                >
                  Contacter l'équipe presse
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </Button>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}