import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Quote } from 'lucide-react';
import { Testimonial } from './types';

interface TestimonialCardProps {
  testimonial: Testimonial;
}

export function TestimonialCard({ testimonial }: TestimonialCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className="relative group"
    >
      {/* Glowing border effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8">
        {/* Quote icon */}
        <div className="absolute -top-4 -left-4 w-8 h-8 bg-[#685AFF] rounded-full flex items-center justify-center">
          <Quote className="w-4 h-4 text-white" />
        </div>

        {/* Content */}
        <p className="text-gray-300 mb-8 italic">"{testimonial.content}"</p>

        {/* Author */}
        <div className="flex items-center gap-4">
          <img 
            src={testimonial.image} 
            alt={testimonial.author}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h4 className="text-white font-semibold">{testimonial.author}</h4>
            <p className="text-gray-400 text-sm">{testimonial.position}</p>
            <p className="text-[#685AFF] text-sm">{testimonial.company}</p>
          </div>
        </div>
      </Card>
    </motion.div>
  );
}