import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, Brain, Database, Code2, Users2, Target, Rocket } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { ServiceCard } from './ServiceCard';
import { services, processSteps } from './data';

export function Accompagnement() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-7xl mx-auto mb-24">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                  Accompagnement sur Mesure en IA
                </h1>
                <p className="text-xl text-gray-300 mb-8">
                  De la formation à l'implémentation, nous vous accompagnons dans toutes les étapes de votre transformation digitale avec l'IA.
                </p>
                <Button 
                  className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                  size="lg"
                  onClick={() => window.location.hash = 'contact'}
                >
                  Planifier un appel stratégique
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="relative"
              >
                <div className="aspect-video rounded-xl overflow-hidden">
                  <img 
                    src="https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=2940&h=1654"
                    alt="Accompagnement IA"
                    className="w-full h-full object-cover"
                  />
                </div>
              </motion.div>
            </div>
          </div>

          {/* Process Section */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Notre Processus d'Accompagnement
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Une méthodologie éprouvée pour garantir votre succès
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {processSteps.map((step, index) => (
                <motion.div
                  key={step.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="relative group"
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
                  <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-10 h-10 rounded-full bg-[#685AFF]/20 flex items-center justify-center">
                        <span className="text-[#685AFF] font-bold">{index + 1}</span>
                      </div>
                      <step.icon className="w-6 h-6 text-[#685AFF]" />
                    </div>
                    <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
                    <p className="text-gray-300">{step.description}</p>
                  </Card>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Services Section */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Nos Services d'Accompagnement
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Une approche holistique pour transformer votre entreprise avec l'IA
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center bg-gradient-to-r from-[#685AFF]/10 to-purple-600/10 backdrop-blur-sm rounded-xl p-8 border border-gray-800"
          >
            <h2 className="text-3xl font-bold text-white mb-4">
              Prêt à Transformer Votre Entreprise ?
            </h2>
            <p className="text-gray-300 mb-8 text-lg">
              Planifiez un appel stratégique avec nos experts pour discuter de vos besoins spécifiques et découvrir comment nous pouvons vous aider à atteindre vos objectifs.
            </p>
            <Button 
              className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
              size="lg"
              onClick={() => window.location.hash = 'contact'}
            >
              Réserver une consultation gratuite
              <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>
        </div>
      </section>
    </div>
  );
}