import { motion } from 'framer-motion';

interface LogoProps {
  className?: string;
  animate?: boolean;
}

export function Logo({ className = '', animate = true }: LogoProps) {
  const logoVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const logoUrl = "https://cdn.prod.website-files.com/664afa764228ea41dc3c48ab/664b144c87bb5805e32e3733_FORWARD(1).svg";

  if (animate) {
    return (
      <motion.img
        src={logoUrl}
        alt="Forward"
        className={className}
        initial="hidden"
        animate="visible"
        variants={logoVariants}
      />
    );
  }

  return (
    <img
      src={logoUrl}
      alt="Forward"
      className={className}
    />
  );
}