import { motion, useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

const stats = [
  {
    endValue: '10 à 40%',
    label: 'Taux de productivité gagné',
    baseValue: 10,
    endBaseValue: 40,
  },
  {
    endValue: '98%',
    label: 'Taux de recommandation',
    baseValue: 0,
  },
  {
    endValue: '1000+',
    label: 'Professionnels formés',
    baseValue: 0,
  },
];

function CountingNumber({ value, baseValue = 0, endBaseValue = null }) {
  const [count, setCount] = useState(baseValue);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  
  useEffect(() => {
    if (isInView) {
      const endValue = endBaseValue || parseInt(value.replace(/\D/g, ''));
      const duration = 2000;
      const steps = 60;
      const stepValue = (endValue - baseValue) / steps;
      let currentStep = 0;

      const timer = setInterval(() => {
        if (currentStep < steps) {
          setCount(Math.floor(baseValue + stepValue * currentStep));
          currentStep++;
        } else {
          setCount(endValue);
          clearInterval(timer);
        }
      }, duration / steps);

      return () => clearInterval(timer);
    }
  }, [isInView, value, baseValue, endBaseValue]);

  return (
    <span ref={ref}>
      {value === '10 à 40%' ? `10 à ${count}` : count}{value.includes('+') ? '+' : '%'}
    </span>
  );
}

export function Stats() {
  return (
    <section className="w-full relative z-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8"
        >
          {stats.map((stat, index) => (
            <motion.div
              key={stat.endValue}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              {/* Glowing background */}
              <div className="absolute -inset-1 bg-gradient-to-r from-[#685AFF] to-purple-600 opacity-0 group-hover:opacity-20 rounded-lg blur transition duration-500" />
              
              {/* Content */}
              <div className="relative flex flex-col items-center justify-center text-center space-y-2 p-4">
                <motion.div
                  className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 400 }}
                >
                  <CountingNumber 
                    value={stat.endValue} 
                    baseValue={stat.baseValue}
                    endBaseValue={stat.endBaseValue}
                  />
                </motion.div>
                <div className="text-sm sm:text-base text-gray-200 font-medium">
                  {stat.label}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}