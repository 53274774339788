import { motion } from 'framer-motion';
import { Brain, Target, Users } from 'lucide-react';

const features = [
  {
    icon: Brain,
    title: "Expertise Reconnue",
    description: "Formateurs certifiés avec une expérience concrète en IA"
  },
  {
    icon: Target,
    title: "Pratique Intensive",
    description: "80% du temps dédié aux exercices pratiques"
  },
  {
    icon: Users,
    title: "Accompagnement Sur Mesure",
    description: "Attention individuelle et suivi personnalisé pour votre réussite"
  }
];

export function FeaturesSection() {
  return (
    <div className="max-w-7xl mx-auto mb-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl font-bold text-white mb-4">
          Notre Approche Pédagogique
        </h2>
        <p className="text-xl text-gray-300">
          Une formation d'excellence adaptée à vos besoins
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <motion.div
            key={feature.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="relative group"
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
            <div className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8 rounded-lg h-full flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-2xl bg-[#685AFF]/10 flex items-center justify-center mb-6">
                <feature.icon className="w-8 h-8 text-[#685AFF]" />
              </div>
              <h3 className="text-xl font-bold text-white mb-3">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}