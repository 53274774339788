import { useEffect } from 'react';

export function useScrollToTop() {
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      
      requestAnimationFrame(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        if (hash) {
          requestAnimationFrame(() => {
            const element = document.querySelector(hash);
            if (element) {
              const navbarHeight = 60;
              const elementPosition = element.getBoundingClientRect().top;
              const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
            }
          });
        }
      });
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);
}