import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { NetworkBackground } from '../animations/NetworkBackground';
import { Stats } from './Stats';
import { Services } from './Services';
import { ClientCarousel } from './ClientCarousel';
import { Testimonials } from './Testimonials';
import { Logo } from '../ui/logo';

export function Hero() {
  return (
    <div className="relative min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="relative min-h-[100svh] flex items-center justify-center overflow-hidden bg-black">
        {/* Animated Background */}
        <NetworkBackground />

        {/* Hero Content */}
        <div className="relative z-10 w-full max-w-6xl mx-auto px-4 sm:px-6 py-16 sm:py-24">
          <div className="flex flex-col items-center justify-center space-y-8">
            {/* Logo */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="mb-4 sm:mb-8"
            >
              <Logo className="h-8 sm:h-12 w-auto" />
            </motion.div>

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold tracking-tight text-white text-center max-w-4xl mx-auto"
            >
              De la formation à l'implémentation : toutes les clés pour réussir avec l'IA
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-base sm:text-lg md:text-xl text-gray-100 font-medium max-w-3xl mx-auto text-center"
            >
              Des solutions et accompagnements sur mesure pour exploiter tout le potentiel de l'intelligence artificielle
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="mt-4 sm:mt-8"
            >
              <a 
                href="#contact" 
                className="inline-flex items-center justify-center bg-gradient-to-r from-[#EC4578] via-[#615CDA] to-[#3136C6] text-white px-6 sm:px-8 py-3 rounded-full text-base sm:text-lg font-medium hover:opacity-90 transition-all hover:scale-105"
              >
                Contactez-nous <ChevronRight className="inline-block w-4 h-4 ml-2" />
              </a>
            </motion.div>
          </div>

          {/* Stats Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="mt-12 sm:mt-16"
          >
            <Stats />
          </motion.div>
        </div>

        {/* Scroll Indicator */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1 }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-20 hidden sm:block"
        >
          <div className="w-6 h-10 border-2 border-white/50 rounded-full flex items-start justify-center p-1">
            <motion.div
              animate={{ y: [0, 12, 0] }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
              className="w-1 h-2 bg-white/70 rounded-full"
            />
          </div>
        </motion.div>
      </section>

      {/* Services Section */}
      <div className="relative z-10">
        <Services />
      </div>

      {/* Client Carousel */}
      <div className="relative z-10">
        <ClientCarousel />
      </div>

      {/* Testimonials */}
      <div className="relative z-10">
        <Testimonials />
      </div>
    </div>
  );
}