import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Partner } from './types';
import { cn } from '@/lib/utils';

interface PartnerCardProps {
  partner: Partner;
}

export function PartnerCard({ partner }: PartnerCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className="relative group"
    >
      {/* Glowing border effect */}
      <div className={cn(
        "absolute -inset-0.5 bg-gradient-to-r rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur",
        partner.color
      )} />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8">
        {/* Logo */}
        <div className="h-16 mb-6 flex items-center justify-center">
          <img 
            src={partner.logo} 
            alt={partner.name}
            className="max-h-full max-w-full object-contain filter brightness-0 invert opacity-80 group-hover:opacity-100 transition-opacity"
          />
        </div>

        <h3 className="text-xl font-bold text-white mb-4 text-center">{partner.name}</h3>
        <p className="text-gray-300 mb-6 text-center">{partner.description}</p>

        {/* Expertise Areas */}
        <div className="flex flex-wrap gap-2 justify-center">
          {partner.expertise.map((area, index) => (
            <span 
              key={index}
              className="text-sm px-3 py-1 rounded-full bg-[#685AFF]/20 text-[#685AFF]"
            >
              {area}
            </span>
          ))}
        </div>
      </Card>
    </motion.div>
  );
}