import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, Handshake, Globe, Award, Shield } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { teamMembers } from './data';
import { TeamMemberCard } from './TeamMemberCard';

export function Team() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-4xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Notre Équipe
              </h1>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Des professionnels expérimentés qui dirigent notre vision de l'IA générative. Nous transformons des concepts complexes en outils simples et efficaces, adaptés à votre réalité.
              </p>
            </motion.div>
          </div>

          {/* Team Grid */}
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {teamMembers.map((member, index) => (
                <TeamMemberCard key={index} member={member} />
              ))}
            </div>
          </div>

          {/* Join Us Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center mt-24"
          >
            <div className="bg-gradient-to-r from-[#685AFF]/10 to-purple-600/10 backdrop-blur-sm rounded-2xl p-8 border border-gray-800">
              <h2 className="text-3xl font-bold text-white mb-4">
                Rejoignez l'Aventure
              </h2>
              <p className="text-gray-300 mb-8 text-lg">
                Nous sommes toujours à la recherche de talents passionnés par l'IA pour rejoindre notre équipe.
              </p>
              <Button 
                className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                size="lg"
                onClick={() => window.location.hash = 'careers'}
              >
                Voir nos offres d'emploi
                <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
              </Button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}