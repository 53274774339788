import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, Handshake, Globe, Award, Shield } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { partners, testimonials } from './data';
import { PartnerCard } from './PartnerCard';
import { TestimonialCard } from './TestimonialCard';

export function Partners() {
  return (
    <div className="min-h-screen bg-black relative">
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-3xl mx-auto"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-[#685AFF] to-white">
                Nos Partenaires
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                Ensemble, nous construisons l'avenir de l'IA générative à travers des partenariats stratégiques et innovants
              </p>
            </motion.div>
          </div>

          {/* Strategic Partners */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Partenaires Stratégiques
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Des collaborations qui renforcent notre expertise et enrichissent notre offre
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {partners.filter(p => p.type === 'strategic').map((partner, index) => (
                <PartnerCard key={index} partner={partner} />
              ))}
            </div>
          </div>

          {/* Technology Partners */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Partenaires Technologiques
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Les meilleurs outils et technologies pour votre réussite
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {partners.filter(p => p.type === 'technology').map((partner, index) => (
                <PartnerCard key={index} partner={partner} />
              ))}
            </div>
          </div>

          {/* Academic Partners */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Partenaires Académiques
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Des collaborations avec les meilleures institutions éducatives
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {partners.filter(p => p.type === 'academic').map((partner, index) => (
                <PartnerCard key={index} partner={partner} />
              ))}
            </div>
          </div>

          {/* Partner Testimonials */}
          <div className="max-w-7xl mx-auto mb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Témoignages de nos Partenaires
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Ce qu'ils pensent de notre collaboration
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} testimonial={testimonial} />
              ))}
            </div>
          </div>

          {/* Become a Partner */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center bg-gradient-to-r from-[#685AFF]/10 to-purple-600/10 backdrop-blur-sm rounded-xl p-8 border border-gray-800"
          >
            <Handshake className="w-12 h-12 text-[#685AFF] mx-auto mb-6" />
            <h2 className="text-3xl font-bold text-white mb-4">
              Devenez Partenaire
            </h2>
            <p className="text-gray-300 mb-8 text-lg">
              Rejoignez notre écosystème d'innovation et participez à la transformation digitale avec l'IA générative.
            </p>
            <Button 
              className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
              size="lg"
            >
              Nous contacter
              <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>
        </div>
      </section>
    </div>
  );
}