import { useState } from 'react';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { FilterSection } from './components/FilterSection';
import { HeroSection } from './components/HeroSection';
import { StatsSection } from './components/StatsSection';
import { FeaturesSection } from './components/FeaturesSection';
import { levels, domains } from './constants';
import { FormationCard } from './FormationCard';

const formations = [
  {
    title: 'ChatGPT : Embarquez dans l\'Aventure de l\'Intelligence Artificielle Générative',
    image: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    accessible: true,
    level: 'Débutant',
    domains: ['Général', 'Communication'],
    href: '#formation-chatgpt'
  },
  {
    title: 'L\'intelligence artificielle générative au service du commerce',
    image: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    level: 'Intermédiaire',
    domains: ['Commerce', 'Vente'],
    href: '#ia-commerce'
  },
  {
    title: 'Midjourney : Faite de l\'IA, l\'allié de vos créations',
    image: 'https://images.unsplash.com/photo-1550439062-609e1531270e?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    level: 'Avancé',
    domains: ['Créatif', 'Design'],
    href: '#formation-midjourney'
  },
  {
    title: 'L\'IA Générative au Service des Ressources Humaines : Optimisez Vos Processus et Gagnez en Efficacité',
    image: 'https://images.unsplash.com/photo-1521737711867-e3b97375f902?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    level: 'Intermédiaire',
    domains: ['RH', 'Management'],
    href: '#ia-rh'
  },
  {
    title: 'L\'IA Générative pour le Service Juridique : Gagnez du Temps et Sécurisez Vos Analyses',
    image: 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    level: 'Intermédiaire',
    domains: ['Juridique', 'Conformité'],
    href: '#ia-juridique'
  }
];

export function FormationsEntreprise() {
  const [selectedLevel, setSelectedLevel] = useState('Tous');
  const [selectedDomain, setSelectedDomain] = useState('Tous');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleCalendlyClick = () => {
    window.open('https://calendly.com/arnaud-c-lgfv/decouverte-ai-forward', '_blank');
  };

  const filteredFormations = formations.filter(formation => {
    const levelMatch = selectedLevel === 'Tous' || formation.level === selectedLevel;
    const domainMatch = selectedDomain === 'Tous' || formation.domains.includes(selectedDomain);
    return levelMatch && domainMatch;
  });

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-7xl mx-auto mb-12">
            <HeroSection onCalendlyClick={handleCalendlyClick} />
            <StatsSection />
          </div>

          {/* Filters */}
          <FilterSection
            levels={levels}
            domains={domains}
            selectedLevel={selectedLevel}
            selectedDomain={selectedDomain}
            onLevelChange={setSelectedLevel}
            onDomainChange={setSelectedDomain}
            isOpen={isFiltersOpen}
            onToggle={() => setIsFiltersOpen(!isFiltersOpen)}
          />

          {/* Formation Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {filteredFormations.map((formation, index) => (
              <FormationCard key={index} formation={formation} />
            ))}
          </div>

          {/* Features Section */}
          <FeaturesSection />
        </div>
      </section>
    </div>
  );
}