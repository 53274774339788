import { useState } from 'react';
import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { ArrowRight, Calendar, Users, Brain, Clock, MapPin, Building2 } from 'lucide-react';
import { useTrainingSessions } from '@/store/TrainingSessionsContext';

export function TrainingDates() {
  const [selectedFormation, setSelectedFormation] = useState<string>('all');
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const { sessions } = useTrainingSessions();

  const handleDateSelection = (session: any) => {
    // Store the selected date in localStorage or state management
    localStorage.setItem('selectedTrainingDate', JSON.stringify(session));
    // Navigate to booking form
    window.location.hash = 'booking';
  };

  const filteredSessions = sessions.filter(session => {
    const formationMatch = selectedFormation === 'all' || session.formation === selectedFormation;
    const locationMatch = selectedLocation === 'all' || session.location === selectedLocation;
    return formationMatch && locationMatch;
  });

  const uniqueFormations = ['all', ...new Set(sessions.map(session => session.formation))];
  const uniqueLocations = ['all', ...new Set(sessions.map(session => session.location))];

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-4xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Sessions de Formation Individuelles
              </h1>
              <p className="text-xl text-gray-300">
                Réservez votre place pour nos prochaines sessions de formation
              </p>
            </motion.div>
          </div>

          {/* Filters */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-7xl mx-auto mb-12"
          >
            <div className="flex flex-wrap justify-center gap-4">
              {uniqueFormations.map((formation) => (
                <Button
                  key={formation}
                  variant={selectedFormation === formation ? "default" : "outline"}
                  className={`${
                    selectedFormation === formation 
                      ? "bg-[#685AFF] hover:bg-[#685AFF]/90" 
                      : "text-white"
                  }`}
                  onClick={() => setSelectedFormation(formation)}
                >
                  {formation === 'all' ? 'Toutes les formations' : formation}
                </Button>
              ))}
            </div>
            <div className="flex flex-wrap justify-center gap-4 mt-4">
              {uniqueLocations.map((location) => (
                <Button
                  key={location}
                  variant={selectedLocation === location ? "default" : "outline"}
                  className={`${
                    selectedLocation === location 
                      ? "bg-[#685AFF] hover:bg-[#685AFF]/90" 
                      : "text-white"
                  }`}
                  onClick={() => setSelectedLocation(location)}
                >
                  {location === 'all' ? 'Toutes les villes' : location}
                </Button>
              ))}
            </div>
          </motion.div>

          {/* Dates Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-7xl mx-auto"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredSessions.map((session) => (
                <motion.div
                  key={session.id}
                  whileHover={{ y: -5 }}
                  className="relative group"
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
                  
                  <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-6">
                    {/* Formation Title */}
                    <div className="flex items-center gap-2 mb-4">
                      <Brain className="w-5 h-5 text-[#685AFF]" />
                      <h3 className="text-lg font-semibold text-white">{session.formation}</h3>
                    </div>

                    {/* Date & Time */}
                    <div className="space-y-3 mb-6">
                      <div className="flex items-center gap-2 text-gray-300">
                        <Calendar className="w-4 h-4 text-[#685AFF]" />
                        <span>{session.date}</span>
                      </div>
                      <div className="flex items-center gap-2 text-gray-300">
                        <Clock className="w-4 h-4 text-[#685AFF]" />
                        <span>{session.time}</span>
                      </div>
                      <div className="flex items-center gap-2 text-gray-300">
                        <MapPin className="w-4 h-4 text-[#685AFF]" />
                        <span>{session.location}</span>
                      </div>
                      <div className="flex items-center gap-2 text-gray-300">
                        <Users className="w-4 h-4 text-[#685AFF]" />
                        <span>{session.spotsLeft} places disponibles</span>
                      </div>
                    </div>

                    {/* Progress bar for spots left */}
                    <div className="mb-6">
                      <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-[#685AFF]"
                          style={{ 
                            width: `${(session.spotsLeft / session.maxParticipants) * 100}%` 
                          }}
                        />
                      </div>
                      <p className="text-sm text-gray-400 mt-2">
                        {session.spotsLeft} places sur {session.maxParticipants}
                      </p>
                    </div>

                    {/* Price Information */}
                    <div className="mb-6">
                      <div className="flex items-center justify-between">
                        <span className="text-gray-400">Prix</span>
                        <span className="text-2xl font-bold text-white">{session.price}</span>
                      </div>
                    </div>

                    {/* Action Button */}
                    <Button
                      className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                      onClick={() => handleDateSelection(session)}
                      disabled={session.spotsLeft === 0}
                    >
                      {session.spotsLeft === 0 ? 'Complet' : 'Réserver ma place'}
                      <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                    </Button>
                  </Card>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Contact Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto mt-16 text-center"
          >
            <p className="text-gray-300 mb-6">
              Vous ne trouvez pas la date qui vous convient ? Contactez-nous pour connaître nos prochaines sessions.
            </p>
            <Button
              variant="outline"
              className="text-white border-gray-700 hover:bg-white/5"
              onClick={() => window.location.hash = 'contact'}
            >
              Nous contacter
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </motion.div>
        </div>
      </section>
    </div>
  );
}