import { useState, useEffect } from 'react';
import { NotFound } from './components/NotFound';
import { Navbar } from './components/layout/Navbar';
import { Hero } from './components/sections/Hero';
import { Footer } from './components/layout/Footer';
import { FormationsEntreprise } from './components/sections/FormationsEntreprise';
import { FormationsIndividuelles } from './components/sections/FormationsIndividuelles';
import { FormationChatGPT } from './components/sections/FormationChatGPT';
import { FormationMidjourney } from './components/sections/FormationMidjourney';
import { FormationIACommerce } from './components/sections/FormationIACommerce';
import { FormationIARH } from './components/sections/FormationIARH';
import { FormationIAJuridique } from './components/sections/FormationIAJuridique';
import { FormationChatGPTEssentiel } from './components/sections/FormationChatGPTEssentiel';
import { FormationMidjourneyCreatif } from './components/sections/FormationMidjourneyCreatif';
import { FormationIAEntrepreneurs } from './components/sections/FormationIAEntrepreneurs';
import { Accompagnement } from './components/sections/Accompagnement';
import { Team } from './components/sections/Team';
import { UseCases } from './components/sections/UseCases';
import { Partners } from './components/sections/Partners';
import { Blog } from './components/sections/Blog';
import { Contact } from './components/sections/Contact';
import { CGV } from './components/sections/CGV';
import { ReglementInterieur } from './components/sections/ReglementInterieur';
import { MentionsLegales } from './components/sections/MentionsLegales';
import { Qualiopi } from './components/sections/Qualiopi';
import { Booking } from './components/sections/Booking';
import { TrainingDates } from './components/sections/TrainingDates';
import { AdminPanel } from './components/sections/Admin';
import { Careers } from './components/sections/Careers';
import { ThemeProvider } from '@/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import { TrainingSessionsProvider } from './store/TrainingSessionsContext';

// Générer un hash aléatoire pour l'URL admin
const adminHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export default function App() {
  const [currentPage, setCurrentPage] = useState<'home' | 'formations-entreprise' | 'formations-individuelles' | 'formation-chatgpt' | 'formation-midjourney' | 'formation-ia-commerce' | 'formation-ia-rh' | 'formation-ia-juridique' | 'formation-chatgpt-essentiel' | 'formation-midjourney-creatif' | 'formation-ia-entrepreneurs' | 'accompagnement' | 'team' | 'use-cases' | 'partners' | 'blog' | 'contact' | 'cgv' | 'reglement-interieur' | 'mentions-legales' | 'qualiopi' | 'booking' | 'training-dates' | 'admin' | 'careers'>('home');

  useScrollToTop();

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      
      switch (hash) {
        case 'formations-entreprises':
          setCurrentPage('formations-entreprise');
          break;
        case 'formations-individuelles':
          setCurrentPage('formations-individuelles');
          break;
        case 'formation-chatgpt':
          setCurrentPage('formation-chatgpt');
          break;
        case 'formation-midjourney':
          setCurrentPage('formation-midjourney');
          break;
        case 'ia-commerce':
          setCurrentPage('formation-ia-commerce');
          break;
        case 'ia-rh':
          setCurrentPage('formation-ia-rh');
          break;
        case 'ia-juridique':
          setCurrentPage('formation-ia-juridique');
          break;
        case 'chatgpt-essentiel':
          setCurrentPage('formation-chatgpt-essentiel');
          break;
        case 'midjourney-creatif':
          setCurrentPage('formation-midjourney-creatif');
          break;
        case 'ia-entrepreneurs':
          setCurrentPage('formation-ia-entrepreneurs');
          break;
        case 'accompagnement':
          setCurrentPage('accompagnement');
          break;
        case 'notre-equipe':
          setCurrentPage('team');
          break;
        case 'use-cases':
          setCurrentPage('use-cases');
          break;
        case 'nos-partenaires':
          setCurrentPage('partners');
          break;
        case 'actualites':
          setCurrentPage('blog');
          break;
        case 'contact':
          setCurrentPage('contact');
          break;
        case 'cgv':
          setCurrentPage('cgv');
          break;
        case 'reglement-interieur':
          setCurrentPage('reglement-interieur');
          break;
        case 'mentions-legales':
          setCurrentPage('mentions-legales');
          break;
        case 'qualiopi':
          setCurrentPage('qualiopi');
          break;
        case 'booking':
          setCurrentPage('booking');
          break;
        case 'training-dates':
          setCurrentPage('training-dates');
          break;
        case 'careers':
          setCurrentPage('careers');
          break;
        case adminHash:
          setCurrentPage('admin');
          break;
        default:
          setCurrentPage('home');
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TrainingSessionsProvider>
        <div>
          <Navbar />
          <main>
            {currentPage === 'home' ? (
              <Hero />
            ) : !currentPage ? (
              <NotFound />
            ) : currentPage === 'formations-entreprise' ? (
              <FormationsEntreprise />
            ) : currentPage === 'formations-individuelles' ? (
              <FormationsIndividuelles />
            ) : currentPage === 'formation-chatgpt' ? (
              <FormationChatGPT />
            ) : currentPage === 'formation-midjourney' ? (
              <FormationMidjourney />
            ) : currentPage === 'formation-ia-commerce' ? (
              <FormationIACommerce />
            ) : currentPage === 'formation-ia-rh' ? (
              <FormationIARH />
            ) : currentPage === 'formation-ia-juridique' ? (
              <FormationIAJuridique />
            ) : currentPage === 'formation-chatgpt-essentiel' ? (
              <FormationChatGPTEssentiel />
            ) : currentPage === 'formation-midjourney-creatif' ? (
              <FormationMidjourneyCreatif />
            ) : currentPage === 'formation-ia-entrepreneurs' ? (
              <FormationIAEntrepreneurs />
            ) : currentPage === 'accompagnement' ? (
              <Accompagnement />
            ) : currentPage === 'team' ? (
              <Team />
            ) : currentPage === 'use-cases' ? (
              <UseCases />
            ) : currentPage === 'partners' ? (
              <Partners />
            ) : currentPage === 'blog' ? (
              <Blog />
            ) : currentPage === 'contact' ? (
              <Contact />
            ) : currentPage === 'cgv' ? (
              <CGV />
            ) : currentPage === 'reglement-interieur' ? (
              <ReglementInterieur />
            ) : currentPage === 'mentions-legales' ? (
              <MentionsLegales />
            ) : currentPage === 'qualiopi' ? (
              <Qualiopi />
            ) : currentPage === 'booking' ? (
              <Booking />
            ) : currentPage === 'training-dates' ? (
              <TrainingDates />
            ) : currentPage === 'admin' ? (
              <AdminPanel />
            ) : currentPage === 'careers' ? (
              <Careers />
            ) : (
              <NotFound />
            )}
          </main>
          <Footer adminHash={adminHash} />
          <Toaster />
        </div>
      </TrainingSessionsProvider>
    </ThemeProvider>
  );
}