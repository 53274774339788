import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

interface HeroSectionProps {
  onCalendlyClick: () => void;
}

export function HeroSection({ onCalendlyClick }: HeroSectionProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
          Formations d'IA en Entreprise
        </h1>
        <p className="text-xl text-gray-300 mb-8">
          Nous proposons des formations d'excellence en IA générative, avec un mix parfait entre démonstrations pratiques et l'utilisation de ChatGPT et de l'IA Générative en entreprise.
        </p>
        <Button 
          className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
          size="lg"
          onClick={onCalendlyClick}
        >
          Prendre Rendez-vous
          <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
        </Button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <div className="aspect-video rounded-xl overflow-hidden">
          <img 
            src="https://images.unsplash.com/photo-1531482615713-2afd69097998?auto=format&fit=crop&q=80&w=2940&h=1654"
            alt="Formation IA"
            className="w-full h-full object-cover"
          />
        </div>
      </motion.div>
    </div>
  );
}