import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { ChevronDown, ArrowRight } from 'lucide-react';
import { Logo } from '../ui/logo';

const navigation = [
  { 
    name: 'NOS FORMATIONS',
    href: '#formations',
    hasDropdown: true,
    dropdownItems: [
      { name: 'Formations pour entreprises', href: '#formations-entreprises' },
      { name: 'Formations individuelles', href: '#formations-individuelles' }
    ]
  },
  { 
    name: 'ACCOMPAGNEMENT',
    href: '#accompagnement',
    hasDropdown: false
  },
  { 
    name: 'À PROPOS',
    href: '#notre-equipe',
    hasDropdown: true,
    dropdownItems: [
      { name: 'Notre Équipe', href: '#notre-equipe' },
      { name: 'Use Cases IA', href: '#use-cases' }
    ]
  },
  { 
    name: 'ACTUALITÉS',
    href: '#actualites',
    hasDropdown: false
  }
];

export function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);

    // Check if we're on the home page
    const checkPage = () => {
      const hash = window.location.hash;
      setIsHomePage(!hash || hash === '#' || hash === '');
    };
    
    checkPage();
    window.addEventListener('hashchange', checkPage);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('hashchange', checkPage);
    };
  }, []);

  // Show logo if either:
  // 1. We're not on the home page
  // 2. We're on the home page AND user has scrolled
  const shouldShowLogo = !isHomePage || (isHomePage && isScrolled);

  return (
    <header 
      className={cn(
        'fixed top-0 w-full z-50 transition-all duration-300',
        isScrolled ? 'bg-black/90 backdrop-blur-xl' : 'bg-transparent'
      )}
    >
      <nav className="max-w-7xl mx-auto h-[60px] px-6 flex items-center justify-between lg:justify-center relative">
        {/* Logo */}
        <AnimatePresence>
          {shouldShowLogo && (
            <motion.a 
              href="#"
              className="flex items-center lg:absolute lg:left-6"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Logo className="h-8" />
            </motion.a>
          )}
        </AnimatePresence>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center justify-center gap-8">
          {navigation.map((item) => (
            <div
              key={item.name}
              className="relative"
              onMouseEnter={() => setActiveDropdown(item.name)}
              onMouseLeave={() => setActiveDropdown(null)}
            >
              <motion.a
                href={item.href}
                className="text-sm text-white hover:text-white transition-colors flex items-center gap-1 group nav-link"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {item.name}
                {item.hasDropdown && (
                  <ChevronDown className={cn(
                    "w-4 h-4 transition-transform duration-200",
                    activeDropdown === item.name ? "rotate-180" : ""
                  )} />
                )}
              </motion.a>

              {/* Dropdown Menu */}
              <AnimatePresence>
                {item.hasDropdown && item.dropdownItems && activeDropdown === item.name && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute top-full left-0 mt-2 w-64 bg-black/95 backdrop-blur-xl border border-gray-800 rounded-lg overflow-hidden"
                  >
                    <div className="py-2">
                      {item.dropdownItems.map((dropdownItem) => (
                        <motion.a
                          key={dropdownItem.name}
                          href={dropdownItem.href}
                          className="block px-4 py-2 text-sm text-white hover:text-white hover:bg-[#685AFF]/20 transition-colors nav-link"
                          whileHover={{ x: 5 }}
                          transition={{ type: "spring", stiffness: 300 }}
                        >
                          {dropdownItem.name}
                        </motion.a>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>

        {/* Contact Button */}
        <div className="hidden lg:block lg:absolute lg:right-6">
          <motion.div
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            className="relative"
          >
            {/* Animated background gradient */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-[#685AFF] via-purple-600 to-[#685AFF] rounded-full opacity-75"
              animate={{
                backgroundPosition: isHovered ? ['0% 50%', '100% 50%'] : '0% 50%',
              }}
              transition={{
                duration: 3,
                ease: "linear",
                repeat: Infinity,
              }}
              style={{
                backgroundSize: '200% 100%',
              }}
            />
            
            {/* Button content */}
            <motion.a
              href="#contact"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="relative inline-flex items-center gap-2 px-6 py-2 rounded-full text-sm font-medium text-white transition-all nav-link overflow-hidden"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span>CONTACT</span>
              <motion.div
                animate={{ x: isHovered ? 5 : 0 }}
                transition={{ type: "spring", stiffness: 200 }}
              >
                <ArrowRight className="w-4 h-4" />
              </motion.div>
            </motion.a>
          </motion.div>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="lg:hidden text-white p-2 absolute right-6"
        >
          <div className="w-6 h-5 relative flex flex-col justify-between">
            <span className={cn(
              "w-full h-0.5 bg-white transition-all duration-300",
              isMobileMenuOpen ? "rotate-45 translate-y-2" : ""
            )} />
            <span className={cn(
              "w-full h-0.5 bg-white transition-all duration-300",
              isMobileMenuOpen ? "opacity-0" : ""
            )} />
            <span className={cn(
              "w-full h-0.5 bg-white transition-all duration-300",
              isMobileMenuOpen ? "-rotate-45 -translate-y-2" : ""
            )} />
          </div>
        </button>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="lg:hidden absolute top-[60px] left-0 right-0 bg-black/95 backdrop-blur-xl border-t border-gray-800"
            >
              <div className="max-w-7xl mx-auto px-6 py-4">
                {navigation.map((item) => (
                  <div key={item.name} className="py-2">
                    {item.hasDropdown ? (
                      <>
                        <button
                          onClick={() => setActiveDropdown(activeDropdown === item.name ? null : item.name)}
                          className="w-full flex items-center justify-between text-white py-2 nav-link"
                        >
                          {item.name}
                          <ChevronDown className={cn(
                            "w-4 h-4 transition-transform duration-200",
                            activeDropdown === item.name ? "rotate-180" : ""
                          )} />
                        </button>
                        
                        {activeDropdown === item.name && (
                          <div className="pl-4 space-y-2">
                            {item.dropdownItems.map((dropdownItem) => (
                              <a
                                key={dropdownItem.name}
                                href={dropdownItem.href}
                                className="block text-sm text-white hover:text-[#685AFF] py-2 nav-link"
                                onClick={() => setIsMobileMenuOpen(false)}
                              >
                                {dropdownItem.name}
                              </a>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <a
                        href={item.href}
                        className="block text-white py-2 nav-link"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        {item.name}
                      </a>
                    )}
                  </div>
                ))}

                {/* Mobile Contact Button */}
                <motion.a
                  href="#contact"
                  whileHover={{ scale: 1.02 }}
                  className="block mt-4 bg-gradient-to-r from-[#685AFF] via-purple-600 to-[#685AFF] text-white px-6 py-2 rounded-full text-sm font-medium transition-all text-center nav-link"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  CONTACT
                </motion.a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
}