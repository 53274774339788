import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '@/lib/firebase';

export interface TrainingSession {
  id: string;
  formation: string;
  date: string;
  time: string;
  location: string;
  maxParticipants: number;
  spotsLeft: number;
  price: string;
  type: 'individuel';
}

interface TrainingSessionsContextType {
  sessions: TrainingSession[];
  addSession: (session: Omit<TrainingSession, 'id'>) => Promise<void>;
  updateSession: (id: string, session: Partial<TrainingSession>) => Promise<void>;
  deleteSession: (id: string) => Promise<void>;
  loading: boolean;
  error: string | null;
}

const TrainingSessionsContext = createContext<TrainingSessionsContextType | undefined>(undefined);

export function TrainingSessionsProvider({ children }: { children: ReactNode }) {
  const [sessions, setSessions] = useState<TrainingSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'sessions'));
      const sessionsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as TrainingSession[];
      setSessions(sessionsData);
    } catch (err) {
      setError('Erreur lors du chargement des sessions');
      console.error('Error fetching sessions:', err);
    } finally {
      setLoading(false);
    }
  };

  const addSession = async (sessionData: Omit<TrainingSession, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'sessions'), sessionData);
      const newSession = { id: docRef.id, ...sessionData };
      setSessions(prev => [...prev, newSession]);
    } catch (err) {
      setError('Erreur lors de l\'ajout de la session');
      console.error('Error adding session:', err);
      throw err;
    }
  };

  const updateSession = async (id: string, sessionData: Partial<TrainingSession>) => {
    try {
      const sessionRef = doc(db, 'sessions', id);
      await updateDoc(sessionRef, sessionData);
      setSessions(prev => prev.map(session => 
        session.id === id ? { ...session, ...sessionData } : session
      ));
    } catch (err) {
      setError('Erreur lors de la mise à jour de la session');
      console.error('Error updating session:', err);
      throw err;
    }
  };

  const deleteSession = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'sessions', id));
      setSessions(prev => prev.filter(session => session.id !== id));
    } catch (err) {
      setError('Erreur lors de la suppression de la session');
      console.error('Error deleting session:', err);
      throw err;
    }
  };

  return (
    <TrainingSessionsContext.Provider value={{ 
      sessions, 
      addSession, 
      updateSession, 
      deleteSession,
      loading,
      error
    }}>
      {children}
    </TrainingSessionsContext.Provider>
  );
}

export function useTrainingSessions() {
  const context = useContext(TrainingSessionsContext);
  if (context === undefined) {
    throw new Error('useTrainingSessions must be used within a TrainingSessionsProvider');
  }
  return context;
}