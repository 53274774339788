import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Mail, Building, Shield, Lock, Cookie, Phone, MapPin } from 'lucide-react';

export function CGV() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-12 text-white text-center">
              CONDITIONS GENERALES DE VENTE
            </h1>

            <div className="prose prose-invert max-w-none space-y-8">
              {/* Partie Générale */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2>Partie Générale</h2>

                <h3>1. Introduction</h3>
                <p>AI Forward, désigné ci-après le "Fournisseur", est une entreprise spécialisée dans le développement de solutions basées sur l'intelligence artificielle (IA) et dans la formation continue d'adultes axée sur l'IA. Fondée avec l'ambition de combler le fossé entre les progrès technologiques et les compétences professionnelles, AI Forward se consacre à offrir des solutions innovantes et des programmes de formation de qualité pour permettre à ses clients et stagiaires de se développer et d'exceller dans un environnement professionnel en constante évolution.</p>

                <h3>2. Définitions</h3>
                <p>Dans les présentes CGV, les termes suivants ont la signification qui leur est donnée ci-après :</p>
                <ul>
                  <li>"Client" désigne toute personne physique ou morale qui s'engage avec AI Forward pour l'utilisation des solutions d'IA ou la participation aux programmes de formation.</li>
                  <li>"Stagiaire" réfère à tout individu participant à un programme de formation dispensé par AI Forward.</li>
                  <li>"Solutions d'IA" couvrent les logiciels, plateformes et applications développés ou fournis par AI Forward dans le domaine de l'intelligence artificielle.</li>
                  <li>"Programmes de Formation" désignent les sessions de formation continue organisées par AI Forward, axées sur l'acquisition et le développement de compétences en IA.</li>
                </ul>

                <h3>3. Objet des CGV</h3>
                <p>Les présentes CGV ont pour objet de définir les termes et conditions sous lesquels le Fournisseur propose ses Solutions d'IA et ses Programmes de Formation aux Clients et Stagiaires. Elles régissent l'accès et l'utilisation des services offerts par AI Forward, ainsi que la relation commerciale entre AI Forward et ses Clients/Stagiaires.</p>

                <h3>4. Conditions d'Accès aux Services</h3>
                <p>L'accès aux Services d'AI Forward est conditionné à l'acceptation et au respect des présentes CGV. Le Fournisseur se réserve le droit de modifier les critères d'éligibilité et les procédures d'accès à tout moment, pour assurer la conformité avec les réglementations en vigueur et les standards de qualité.</p>

                <h3>5. Obligations Générales d'AI Forward</h3>
                <p>AI Forward s'engage à fournir ses Solutions d'IA et ses Programmes de Formation conformément aux standards de qualité élevés, en veillant à l'actualité et à la pertinence des contenus proposés. Le Fournisseur garantit une assistance technique et pédagogique adaptée pour favoriser une expérience utilisateur optimale.</p>

                <h3>6. Obligations et Responsabilités des Clients et Stagiaires</h3>
                <p>Les Clients et Stagiaires s'engagent à utiliser les services fournis par AI Forward de manière conforme aux présentes CGV, aux lois applicables, et dans le respect des droits de propriété intellectuelle du Fournisseur. Ils s'engagent également à fournir des informations exactes lors de leur inscription et à maintenir la confidentialité des identifiants de connexion.</p>

                <h3>7. Propriété Intellectuelle</h3>
                <p>Tout contenu, y compris les matériaux pédagogiques, logiciels, marques, logos et brevets, fournis par AI Forward dans le cadre de ses Services, reste la propriété exclusive du Fournisseur. L'utilisation de ces contenus est accordée sous licence aux Clients et Stagiaires uniquement pour les besoins de l'utilisation des Services et de la formation.</p>

                <h3>8. Modification des CGV</h3>
                <p>AI Forward se réserve le droit de modifier les présentes CGV à tout moment. Les modifications prendront effet immédiatement après leur publication sur le site Web du Fournisseur ou après leur communication aux Clients et Stagiaires par tout autre moyen approprié.</p>

                <h3>9. Loi Applicable et Résolution des Conflits</h3>
                <p>Les présentes CGV sont régies par la loi française. Tout litige relatif à leur interprétation ou à leur exécution sera soumis aux tribunaux compétents du lieu du siège social d'AI Forward, sauf règlement amiable préalable entre les parties.</p>
              </motion.div>

              {/* Facturation et Paiement */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h3>10. Conditions de Facturation et de Paiement</h3>
                <p>AI Forward s'engage à fournir une transparence et une clarté maximales concernant les modalités financières liées à l'utilisation de ses Solutions d'IA et à la participation à ses Programmes de Formation.</p>
                
                <h4>Devis et Accord Préalable :</h4>
                <p>Pour toute prestation, un devis détaillé sera fourni au Client, précisant le contenu du service, le coût total, et les modalités de paiement. L'acceptation du devis par le Client ou le Stagiaire constitue un accord contraignant entre les parties.</p>
                
                <h4>Modalités de Paiement :</h4>
                <p>Les paiements peuvent être effectués par virement bancaire ou prélèvement SEPA, selon les termes spécifiés dans le devis accepté. Sauf indication contraire dans le devis, les factures sont payables sous 30 jours à compter de la date de facture.</p>
                
                <h4>Facturation :</h4>
                <p>Les licences d'utilisation sont facturées à terme à échoir, et les factures sont envoyées par voie électronique. Un acompte de 30% est requis pour la confirmation de l'inscription aux Programmes de Formation, sauf conditions particulières stipulées dans le devis.</p>
                
                <h4>Politique d'Annulation :</h4>
                <p>Les annulations ou reports de services doivent être communiqués par écrit. Aucune pénalité n'est appliquée jusqu'à 10 jours avant le service. Entre 4 et 9 jours, une pénalité de 50% est facturée, et de 100% si l'annulation intervient moins de 3 jours avant.</p>
                
                <h4>Pénalités de Retard :</h4>
                <p>Tout paiement en retard entraînera l'application d'intérêts de retard au taux de la Banque Centrale Européenne plus 10 points, dès le lendemain de la date d'échéance jusqu'au paiement. Une indemnité forfaitaire de 40 euros pour frais de recouvrement sera également exigée. AI Forward se réserve le droit de suspendre l'accès aux services en cas de retard de paiement.</p>
              </motion.div>

              {/* Solutions d'IA */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2>Partie Spécifique aux Solutions d'IA</h2>

                <h3>11. Utilisation des Solutions d'IA</h3>
                <p>AI Forward fournit des solutions avancées d'intelligence artificielle destinées à optimiser les processus opérationnels des organisations clientes. Les clients s'engagent à utiliser ces solutions conformément aux directives d'AI Forward, dans le respect total des législations en vigueur et sans violer les droits de tiers.</p>
                
                <h4>Accès :</h4>
                <p>L'accès aux Solutions d'IA nécessite la création d'un compte utilisateur sécurisé et l'acceptation des conditions d'utilisation spécifiques. Les Clients désigneront un ou plusieurs administrateurs au sein de leur organisation comme principaux interlocuteurs pour le support technique.</p>
                
                <h4>Restrictions :</h4>
                <p>Les Clients s'engagent à ne pas modifier, altérer ou utiliser les Solutions d'IA à des fins non autorisées, incluant toute tentative de reverse engineering ou de contournement des mesures de sécurité.</p>

                <h3>12. Licence et Droits d'Utilisation</h3>
                <p>AI Forward accorde aux Clients une licence non exclusive, non transférable et révocable, permettant l'utilisation des Solutions d'IA dans le cadre strictement défini par ces CGV, pour leurs besoins internes.</p>
                
                <h4>Étendue de la Licence :</h4>
                <p>Cette licence comprend le droit d'accès et d'utilisation des Solutions d'IA, y compris pour les mises à jour et nouvelles versions disponibles.</p>
                
                <h4>Limitations :</h4>
                <p>Toute utilisation des Solutions d'IA au-delà des limites fixées par cette licence requiert un accord écrit et préalable d'AI Forward.</p>

                <h3>13. Support Technique et Maintenance</h3>
                <p>AI Forward s'engage à fournir un support technique dédié aux Clients pour les assister dans la mise en œuvre et l'exploitation quotidienne des Solutions d'IA, garantissant ainsi une expérience utilisateur optimale.</p>
                
                <h4>Assistance Technique :</h4>
                <p>Les Clients peuvent contacter le support technique pour toute question ou problème technique via l'adresse e-mail : support@ai-forward.com. Les administrateurs désignés par le Client agiront en tant qu'interlocuteurs principaux pour toutes communications relatives au support.</p>
                
                <h4>Délai de Réponse :</h4>
                <p>AI Forward s'engage à répondre aux demandes de support dans un délai de deux (2) jours ouvrés, s'efforçant d'apporter des solutions efficaces et adaptées aux besoins des Clients.</p>
                
                <h4>Maintenance :</h4>
                <p>Des mises à jour régulières seront effectuées pour améliorer la performance des Solutions d'IA et intégrer de nouvelles fonctionnalités, assurant ainsi la continuité et l'évolutivité des services fournis.</p>
              </motion.div>

              {/* Programmes de Formation */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2>Partie Spécifique aux Programmes de Formation</h2>

                <h3>14. Programmes de Formation</h3>
                <p>AI Forward propose une gamme complète de programmes de formation en intelligence artificielle destinés aux professionnels de tous niveaux. Notre objectif est d'offrir des formations qui non seulement répondent aux exigences actuelles du marché mais qui préparent également nos stagiaires aux innovations futures dans le domaine de l'IA.</p>
                
                <h4>Contenu et Objectifs :</h4>
                <p>Les formations sont soigneusement conçues pour couvrir aussi bien les fondamentaux de l'IA que des sujets avancés, adaptant le contenu aux besoins et aux niveaux de compétence des stagiaires.</p>
                
                <h4>Engagement Qualiopi :</h4>
                <p>AI Forward est engagé dans une démarche de qualité conforme au référentiel Qualiopi, garantissant ainsi l'excellence de nos programmes de formation et la satisfaction des stagiaires.</p>

                <h3>15. Inscription et Conditions de Participation</h3>
                <p>La participation aux programmes de formation d'AI Forward est soumise à des conditions claires, visant à assurer une expérience d'apprentissage optimale pour tous les stagiaires.</p>
                
                <h4>Prérequis et Accessibilité :</h4>
                <p>Certaines formations peuvent nécessiter des prérequis spécifiques. AI Forward s'engage à faciliter l'accès à ses formations pour les personnes en situation de handicap. Pour plus de détails sur les prérequis et les mesures d'accessibilité, veuillez consulter notre règlement intérieur.</p>
                
                <h4>Procédures d'Inscription :</h4>
                <p>Les candidats doivent s'inscrire via notre formulaire d'inscription en ligne, s'engageant à respecter les modalités de participation énoncées dans le règlement intérieur, disponible sur notre site web ou sur demande.</p>

                <h3>16. Suivi Pédagogique et Évaluation</h3>
                <p>AI Forward met en œuvre un suivi pédagogique rigoureux et des évaluations pour mesurer l'efficacité de l'apprentissage et garantir la progression de chaque stagiaire.</p>
                
                <h4>Évaluation des Acquis :</h4>
                <p>Des évaluations formatives et sommatives sont intégrées au parcours de formation pour assurer une mesure objective des compétences acquises.</p>
                
                <h4>Feedback Continu :</h4>
                <p>Le feedback des stagiaires est sollicité tout au long de la formation pour améliorer continuellement la qualité de nos programmes.</p>

                <h3>17. Certification et Reconnaissance des Acquis</h3>
                <p>Bien que les certifications officiellement reconnues soient en cours de développement, AI Forward s'engage à fournir une validation des compétences acquises par les stagiaires à l'issue des formations.</p>
                
                <h4>Attestation de Participation :</h4>
                <p>En attendant la mise en place de certifications reconnues, une attestation de participation sera délivrée, confirmant l'engagement du stagiaire et reconnaissant les compétences et connaissances acquises durant la formation.</p>
                
                <h4>Développement de Certifications :</h4>
                <p>AI Forward travaille activement à l'élaboration et à la reconnaissance officielle de ses certifications, visant à enrichir la valeur professionnelle des formations pour les stagiaires.</p>

                <h3>18. Utilisation des Supports de Formation</h3>
                <p>Les supports de formation et les ressources pédagogiques fournies par AI Forward dans le cadre de ses Programmes de Formation sont destinés à un usage personnel et non commercial par le stagiaire. Ils ne peuvent être distribués, partagés, ou utilisés pour former d'autres personnes sans l'autorisation écrite d'AI Forward.</p>
                <p>La reproduction partielle ou totale, la diffusion, la vente, la location, ou toute autre forme de mise à disposition de ces supports à des tiers non autorisés par AI Forward sont strictement interdites. Les stagiaires s'engagent à ne pas utiliser ces ressources de manière à porter atteinte aux droits de propriété intellectuelle d'AI Forward ou de tiers.</p>
              </motion.div>

              {/* Dispositions Communes */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <h2>Dispositions Communes</h2>

                <h3>19. Réclamations et Médiation</h3>
                <p>AI Forward s'engage à maintenir un haut niveau de satisfaction pour tous ses Clients et Stagiaires. En cas de réclamation concernant nos services, les procédures suivantes sont mises en place :</p>
                
                <h4>Procédure de Réclamation :</h4>
                <p>Les Clients et Stagiaires sont invités à soumettre leurs réclamations via l'adresse e-mail support@ai-forward.com ou directement sur notre site web, en décrivant clairement l'objet de leur insatisfaction. AI Forward s'engage à répondre à toute réclamation dans un délai de 2 jours ouvrés et s'engage à traiter toutes les réclamations de manière équitable et diligente.</p>
                
                <h4>Médiation :</h4>
                <p>En cas de litige non résolu, les parties peuvent recourir à un médiateur externe. AI Forward fournira les coordonnées d'un médiateur compétent sur demande, favorisant une résolution amiable des différends.</p>

                <h3>20. Confidentialité et Protection des Données Personnelles</h3>
                <p>Conformément au Règlement Général sur la Protection des Données (RGPD), AI Forward prend très au sérieux la confidentialité et la sécurité des informations personnelles de ses Clients et Stagiaires. Nous nous engageons à traiter toutes les données personnelles dans le respect des principes de protection des données et de confidentialité, assurant une gestion sécurisée et responsable des informations recueillies.</p>
                
                <h4>Confidentialité :</h4>
                <p>AI Forward s'engage à ne pas divulguer, vendre ou louer les données personnelles à des tiers sans le consentement explicite de la personne concernée, sauf si requis par la loi ou dans le cadre d'une procédure judiciaire. Toutes les informations reçues de la part des Clients et Stagiaires seront utilisées exclusivement dans le but de fournir et d'améliorer les services offerts, de traiter les inscriptions aux Programmes de Formation, et de gérer les relations commerciales.</p>
                
                <h4>Collecte et Utilisation des Données :</h4>
                <p>Les données personnelles collectées sont utilisées exclusivement dans le cadre de la fourniture de nos services, dans le respect de notre politique de confidentialité disponible sur notre site web.</p>
                
                <h4>Sécurité et Confidentialité :</h4>
                <p>Des mesures techniques et organisationnelles sont mises en place pour assurer la sécurité des données personnelles contre tout accès non autorisé ou toute divulgation.</p>
                
                <h4>Exercice des droits RGPD :</h4>
                <p>Le Client ou Stagiaire peuvent exercer leur droit d'opposition, d'accès, de modification, de portabilité ou de suppression en en faisant la demande à l'adresse support@ai-forward.com.</p>

                <h3>21. Limitation de Responsabilité</h3>
                <p>Le Client et le Stagiaire sont seuls responsables des Données qu'ils utilisent, traitent ou stockent dans le cadre de l'utilisation des services d'AI-Forward. Dans le cadre de l'utilisation de Solutions D'IA, le client s'engage à respecter les CGU de la solution choisie.</p>
                <p>AI Forward s'efforce de fournir des Solutions d'IA et des Programmes de Formation de haute qualité. Néanmoins, le Fournisseur ne saurait être tenu responsable des dommages indirects tels que la perte de profits, d'activité, ou de données, qui pourraient survenir de l'utilisation de ces services. La responsabilité d'AI Forward sera limitée aux dommages directs et prouvés, et ne pourra excéder le montant total des paiements effectués par le Client ou le Stagiaire à AI Forward pour le service en question au cours des 12 derniers mois précédant l'incident.</p>
                <p>Le Client est seul responsable des Données qu'il utilise, traite ou stocke dans le cadre de l'utilisation de la Solution.</p>

                <h3>22. Force Majeure</h3>
                <p>AI Forward ne saurait être tenu pour responsable de la non-exécution ou du retard dans l'exécution de l'une de ses obligations décrites dans les présentes CGV en cas de survenance d'un événement de force majeure habituellement reconnu par la jurisprudence.</p>
                
                <h4>Notification :</h4>
                <p>En cas d'événement de force majeure, conformément à l'article 1218 du Code civile et à la jurisprudence, AI Forward s'engage à notifier ses clients et stagiaires dans les plus brefs délais et à prendre toutes les mesures possibles pour minimiser les impacts sur la fourniture de ses services.</p>

                <h3>23. Dispositions Finales</h3>
                
                <h4>Intégralité de l'Accord :</h4>
                <p>Les présentes CGV constituent l'intégralité de l'accord en complément de toute convention de formation ou contrat de licence entre AI Forward et ses clients/stagiaires concernant l'objet des présentes et remplacent toutes communications et propositions antérieures, écrites ou orales.</p>
                
                <h4>Cession :</h4>
                <p>Les clients et stagiaires ne peuvent céder leurs droits ou obligations en vertu des présentes CGV sans l'accord préalable écrit d'AI Forward.</p>
                
                <h4>Notifications :</h4>
                <p>Toutes les notifications en vertu des présentes CGV doivent être adressées par écrit et seront considérées comme dûment données lorsqu'elles sont envoyées à l'adresse e-mail ou postale indiquée par les parties.</p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}