import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Service } from './types';
import { cn } from '@/lib/utils';

interface ServiceCardProps {
  service: Service;
}

export function ServiceCard({ service }: ServiceCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className="relative group h-full"
    >
      {/* Glowing border effect */}
      <div className={cn(
        "absolute -inset-0.5 bg-gradient-to-r rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur",
        service.color
      )} />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8 h-full flex flex-col">
        <div className={cn(
          "w-12 h-12 rounded-lg bg-gradient-to-r p-3 mb-6 flex-shrink-0",
          service.color
        )}>
          <service.icon className="w-full h-full text-white" />
        </div>

        <h3 className="text-xl font-bold text-white mb-4">{service.title}</h3>
        <p className="text-gray-300 mb-6">{service.description}</p>

        <ul className="space-y-3 mt-auto">
          {service.features.map((feature, index) => (
            <li key={index} className="flex items-start gap-3">
              <span className="text-[#685AFF]">•</span>
              <span className="text-gray-300">{feature}</span>
            </li>
          ))}
        </ul>
      </Card>
    </motion.div>
  );
}