import { useState } from 'react';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { FilterSection } from './components/FilterSection';
import { HeroSection } from './components/HeroSection';
import { StatsSection } from './components/StatsSection';
import { FeaturesSection } from './components/FeaturesSection';
import { levels, domains } from './constants';
import { FormationCard } from './FormationCard';

const formations = [
  {
    title: 'ChatGPT Essentiel',
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    accessible: true,
    level: 'Débutant',
    domains: ['Général', 'Communication'],
    href: '#chatgpt-essentiel',
    price: '900€ HT',
    description: 'Maîtrisez les fondamentaux de ChatGPT et de l\'IA générative pour améliorer votre productivité quotidienne.'
  },
  {
    title: 'Midjourney Créatif',
    image: 'https://images.unsplash.com/photo-1550439062-609e1531270e?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '7 heures (1 jour)',
    type: 'Formation présentielle',
    level: 'Intermédiaire',
    domains: ['Créatif', 'Design'],
    href: '#midjourney-creatif',
    price: '900€ HT',
    description: 'Explorez le potentiel créatif de l\'IA pour générer des visuels professionnels uniques.'
  },
  {
    title: 'IA pour Entrepreneurs',
    image: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80&w=2940&h=1654',
    duration: '14 heures (2 jours)',
    type: 'Formation présentielle',
    level: 'Intermédiaire',
    domains: ['Business', 'Marketing'],
    href: '#ia-entrepreneurs',
    price: '900€ HT',
    description: 'Optimisez votre activité entrepreneuriale grâce aux outils d\'IA générative.'
  }
];

export function FormationsIndividuelles() {
  const [selectedLevel, setSelectedLevel] = useState('Tous');
  const [selectedDomain, setSelectedDomain] = useState('Tous');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleCalendlyClick = () => {
    window.open('https://calendly.com/arnaud-c-lgfv/decouverte-ai-forward', '_blank');
  };

  const filteredFormations = formations.filter(formation => {
    const levelMatch = selectedLevel === 'Tous' || formation.level === selectedLevel;
    const domainMatch = selectedDomain === 'Tous' || formation.domains.includes(selectedDomain);
    return levelMatch && domainMatch;
  });

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-7xl mx-auto mb-12">
            <HeroSection onCalendlyClick={handleCalendlyClick} />
            <StatsSection />
          </div>

          {/* Filters */}
          <FilterSection
            levels={levels}
            domains={domains}
            selectedLevel={selectedLevel}
            selectedDomain={selectedDomain}
            onLevelChange={setSelectedLevel}
            onDomainChange={setSelectedDomain}
            isOpen={isFiltersOpen}
            onToggle={() => setIsFiltersOpen(!isFiltersOpen)}
          />

          {/* Formation Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {filteredFormations.map((formation, index) => (
              <FormationCard key={index} formation={formation} />
            ))}
          </div>

          {/* Features Section */}
          <FeaturesSection />
        </div>
      </section>
    </div>
  );
}