import { motion } from 'framer-motion';

const clients = [
  {
    name: 'Gendarmerie Nationale',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/Gendarmerie_nationale_logo.svg',
    description: 'Force de sécurité intérieure française'
  },
  {
    name: 'Valabre',
    logo: 'https://www.valabre.com/wp-content/uploads/LOGO-VALABRE-COUL-CADRE.png',
    description: 'École Nationale Supérieure des Officiers Sapeurs-Pompiers'
  },
  {
    name: 'Copylot Group',
    logo: 'https://wio.blob.core.windows.net/werecruit/d78fd8bc-66c7-4271-925e-fe0abd982f7a.png',
    description: 'Expert en solutions innovantes'
  },
  {
    name: 'Nintendo',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Nintendo.svg/1280px-Nintendo.svg.png',
    description: 'Leader mondial du divertissement interactif'
  },
  {
    name: "La Chaîne de l'Espoir",
    logo: 'https://0jour.chainedelespoir.org/logo.png?_cchid=b58ebdc0694fd95e005cb80a06ac1180',
    description: 'ONG médicale et humanitaire internationale'
  },
  {
    name: "Femmes Chefs D'Entreprises",
    logo: 'https://www.actifreso.fr/wp-content/uploads/2022/03/logo-FCE-NB-scaled.jpeg',
    description: 'Premier réseau d\'entrepreneures en France'
  }
];

// Duplicate the array to create a seamless loop
const duplicatedClients = [...clients, ...clients];

export function ClientCarousel() {
  return (
    <section className="w-full py-12 overflow-hidden">
      <div className="max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            Ils nous font confiance
          </h2>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Des organisations qui transforment leur avenir avec notre expertise en IA
          </p>
        </motion.div>

        <div className="relative overflow-hidden">
          <motion.div
            className="flex gap-8 py-8"
            animate={{
              x: '-50%'
            }}
            transition={{
              x: {
                duration: 20,
                repeat: Infinity,
                ease: "linear",
                repeatType: "loop"
              }
            }}
            style={{
              width: 'fit-content'
            }}
          >
            {duplicatedClients.map((client, index) => (
              <div
                key={`${client.name}-${index}`}
                className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 rounded-lg p-8 w-[300px] h-[160px] flex items-center justify-center group hover:bg-black/60 transition-all duration-300"
              >
                <img
                  src={client.logo}
                  alt={client.name}
                  className="max-h-24 w-auto object-contain transition-all duration-300 group-hover:scale-110"
                />
                
                {/* Hover overlay */}
                <div className="absolute inset-0 bg-black/80 flex items-center justify-center p-4 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="text-center">
                    <h3 className="text-white font-semibold mb-2">{client.name}</h3>
                    <p className="text-sm text-gray-300">{client.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>

        {/* Trust indicators */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-center"
        >
          <div>
            <h3 className="text-3xl font-bold text-white mb-2">80+</h3>
            <p className="text-gray-300">Organisations partenaires</p>
          </div>
          <div>
            <h3 className="text-3xl font-bold text-white mb-2">95%</h3>
            <p className="text-gray-300">Taux de satisfaction</p>
          </div>
          <div>
            <h3 className="text-3xl font-bold text-white mb-2">2+ ans</h3>
            <p className="text-gray-300">De collaboration moyenne</p>
          </div>
        </motion.div>
      </div>
    </section>
  );
}