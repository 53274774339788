import { motion } from 'framer-motion';
import { Search, BookOpen, Code, Rocket } from 'lucide-react';

const steps = [
  {
    icon: Search,
    title: "Audit personnalisé",
    description: "Identification de vos besoins et opportunités"
  },
  {
    icon: BookOpen,
    title: "Formation des équipes",
    description: "Apprentissage des outils et méthodologies IA"
  },
  {
    icon: Code,
    title: "Développement sur mesure",
    description: "Création de solutions adaptées à vos processus"
  },
  {
    icon: Rocket,
    title: "Déploiement et suivi",
    description: "Mise en production et accompagnement continu"
  }
];

export function ProcessTimeline() {
  return (
    <div className="relative">
      {/* Timeline line */}
      <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-px bg-gradient-to-b from-[#685AFF] via-purple-600 to-[#685AFF]/50" />

      <div className="relative">
        {steps.map((step, index) => {
          const isEven = index % 2 === 0;
          
          return (
            <div key={step.title} className="mb-16 last:mb-0">
              <motion.div
                initial={{ opacity: 0, x: isEven ? -50 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className={`flex items-center gap-8 ${isEven ? 'flex-row' : 'flex-row-reverse'}`}
              >
                {/* Content */}
                <div className={`w-1/2 ${isEven ? 'text-right' : 'text-left'}`}>
                  <div className="relative group">
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
                    <div className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-6 rounded-lg">
                      <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
                      <p className="text-gray-300">{step.description}</p>
                    </div>
                  </div>
                </div>

                {/* Icon */}
                <div className="relative">
                  <div className="w-12 h-12 rounded-full bg-gradient-to-r from-[#685AFF] to-purple-600 p-3 relative z-10">
                    <step.icon className="w-full h-full text-white" />
                  </div>
                  {/* Connecting line */}
                  <div className={`absolute top-1/2 ${isEven ? '-right-4' : '-left-4'} w-4 h-px bg-gradient-to-${isEven ? 'r' : 'l'} from-[#685AFF] to-transparent`} />
                </div>

                {/* Empty space for alignment */}
                <div className="w-1/2" />
              </motion.div>
            </div>
          );
        })}
      </div>
    </div>
  );
}