import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { ArrowRight, Award, Shield, CheckCircle2 } from 'lucide-react';

export function Qualiopi() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-12">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Award className="w-16 h-16 text-[#685AFF] mx-auto mb-6" />
                <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                  Notre Certification Qualiopi
                </h1>
                <p className="text-xl text-gray-300 mb-8">
                  AI Forward est fier d'être certifié Qualiopi, attestant de la qualité de nos formations professionnelles
                </p>
              </motion.div>
            </div>

            {/* Certificate Image */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="relative group mb-12"
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
              <div className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 rounded-lg p-4">
                <img 
                  src="https://i.ibb.co/c2Rc7Z8/certificat-ai-forward-2024-2027pdf-1-1-page-0001.jpg"
                  alt="Certification Qualiopi AI Forward"
                  className="w-full h-auto rounded-lg"
                />
              </div>
            </motion.div>

            {/* Benefits Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="bg-black/50 backdrop-blur-sm border border-gray-800 rounded-xl p-8 mb-12"
            >
              <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-3">
                <Shield className="w-6 h-6 text-[#685AFF]" />
                Les Avantages de la Certification Qualiopi
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  "Garantie de qualité des formations",
                  "Processus pédagogiques validés",
                  "Suivi personnalisé des apprenants",
                  "Formateurs hautement qualifiés",
                  "Amélioration continue des programmes",
                  "Éligibilité aux financements publics"
                ].map((benefit, index) => (
                  <div key={index} className="flex items-start gap-3">
                    <CheckCircle2 className="w-5 h-5 text-[#685AFF] flex-shrink-0 mt-1" />
                    <span className="text-gray-300">{benefit}</span>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* CTA Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              className="text-center"
            >
              <Button 
                className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                size="lg"
                onClick={() => window.location.hash = 'contact'}
              >
                Découvrir nos formations certifiées
                <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
              </Button>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}