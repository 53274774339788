import { motion } from 'framer-motion';
import { Linkedin, MapPin, Phone, Mail } from 'lucide-react';
import { Logo } from '../ui/logo';
import { useState } from 'react';

interface FooterProps {
  adminHash: string;
}

const footerLinks = {
  formations: [
    { name: 'Formations entreprises', href: '#formations-entreprises' },
    { name: 'Formations individuelles', href: '#formations-individuelles' },
  ],
  solutions: [
    { name: 'Accompagnement', href: '#accompagnement' },
  ],
  company: [
    { name: 'À propos', href: '#about' },
    { name: 'Notre équipe', href: '#notre-equipe' },
    { name: 'Use Cases IA', href: '#use-cases' },
    { name: 'Presse', href: '#presse' },
  ],
  legal: [
    { name: 'CGV', href: '#cgv' },
    { name: 'Règlement Intérieur', href: '#reglement-interieur' },
    { name: 'Mentions légales', href: '#mentions-legales' },
  ],
  accessibility: [
    { name: 'Annuaire handicap', href: '#handicap' },
    { name: 'Attestation Qualiopi', href: '#qualiopi' },
  ],
};

export function Footer({ adminHash }: FooterProps) {
  const [qualiopiClicks, setQualiopiClicks] = useState(0);

  const handleQualiopiClick = () => {
    const newCount = qualiopiClicks + 1;
    setQualiopiClicks(newCount);
    
    if (newCount === 5) {
      setQualiopiClicks(0); // Reset counter
      window.location.hash = adminHash;
    }
  };

  return (
    <footer className="relative bg-black rounded-t-3xl">
      <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/5 to-transparent pointer-events-none rounded-t-3xl" />

      <div className="relative max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-8 px-4 sm:px-6 lg:px-8 py-8">
          {/* Brand section */}
          <div className="lg:col-span-1 space-y-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <Logo className="h-8 w-auto text-white" animate={false} />
              
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-3">
                <motion.div 
                  className="flex items-start gap-3 text-gray-300 group cursor-pointer"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <MapPin className="w-4 h-4 mt-1 flex-shrink-0 text-[#685AFF]" />
                  <p className="text-sm group-hover:text-white transition-colors">20 Rue Jules Vales, Paris, 75011</p>
                </motion.div>
                
                <motion.a 
                  href="mailto:infos@ai-forward.com" 
                  className="flex items-center gap-3 text-gray-300 group"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Mail className="w-4 h-4 flex-shrink-0 text-[#685AFF]" />
                  <span className="text-sm group-hover:text-white transition-colors">infos@ai-forward.com</span>
                </motion.a>
                
                <motion.a 
                  href="tel:+33143704491" 
                  className="flex items-center gap-3 text-gray-300 group"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Phone className="w-4 h-4 flex-shrink-0 text-[#685AFF]" />
                  <span className="text-sm group-hover:text-white transition-colors">01 43 70 44 91</span>
                </motion.a>

                <motion.a
                  href="https://www.linkedin.com/in/arnaudforward/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 text-gray-300 group"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Linkedin className="w-4 h-4 text-[#685AFF]" />
                  <span className="text-sm group-hover:text-white transition-colors">LinkedIn</span>
                </motion.a>
              </div>
            </motion.div>
          </div>

          {/* Links sections */}
          <div className="lg:col-span-5 grid grid-cols-2 md:grid-cols-5 gap-8">
            {/* Formations */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-sm font-semibold text-white uppercase">Formations</h3>
              <ul className="space-y-3">
                {footerLinks.formations.map((item) => (
                  <motion.li
                    key={item.name}
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white transition-colors">
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Solutions */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-sm font-semibold text-white uppercase">Solutions</h3>
              <ul className="space-y-3">
                {footerLinks.solutions.map((item) => (
                  <motion.li
                    key={item.name}
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white transition-colors">
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Company */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-sm font-semibold text-white uppercase">Entreprise</h3>
              <ul className="space-y-3">
                {footerLinks.company.map((item) => (
                  <motion.li
                    key={item.name}
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white transition-colors">
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Legal */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-sm font-semibold text-white uppercase">Légal</h3>
              <ul className="space-y-3">
                {footerLinks.legal.map((item) => (
                  <motion.li
                    key={item.name}
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white transition-colors">
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Accessibility */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-sm font-semibold text-white uppercase">Accessibilité</h3>
              <ul className="space-y-3">
                {footerLinks.accessibility.map((item) => (
                  <motion.li
                    key={item.name}
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white transition-colors">
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>

          {/* Qualiopi Logo Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            viewport={{ once: true }}
            className="lg:col-span-1 flex flex-col items-center justify-start pt-2"
          >
            <div 
              className="bg-white rounded-xl p-2 shadow-lg cursor-pointer transition-transform hover:scale-105"
              onClick={handleQualiopiClick}
            >
              <img 
                src="https://certifopac.fr/wp-content/uploads/2021/09/LogoQualiopi-300dpi-Avec-Marianne.png"
                alt="Certification Qualiopi"
                className="h-16 w-auto rounded-lg"
              />
            </div>
            <p className="text-xs text-gray-400 text-center mt-2">Certifié Qualiopi</p>
          </motion.div>
        </div>

        {/* Bottom bar */}
        <div className="border-t border-gray-800/50">
          <div className="px-4 sm:px-6 py-3">
            <p className="text-sm text-gray-400 text-center">
              © {new Date().getFullYear()} AI-Forward. Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}