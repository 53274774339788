import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { motion } from 'framer-motion';
import { Phone, Calendar, ArrowRight } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
  name: z.string().min(2, { message: "Le nom doit contenir au moins 2 caractères" }),
  email: z.string().email({ message: "Email invalide" }),
  subject: z.string().min(5, { message: "Le sujet doit contenir au moins 5 caractères" }),
  message: z.string().min(10, { message: "Le message doit contenir au moins 10 caractères" })
});

type FormData = z.infer<typeof formSchema>;

export function Contact() {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: zodResolver(formSchema)
  });

  const handleCalendlyClick = () => {
    window.open('https://calendly.com/arnaud-c-lgfv/decouverte-ai-forward', '_blank');
  };

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true);
    try {
      const templateParams = {
        from_name: data.name,
        reply_to: data.email,
        subject: data.subject,
        message: data.message,
      };
  
      console.log("Données envoyées à EmailJS :", templateParams); // Debug
  
      await emailjs.send(
        'service_s0db8bh',
        'template_1bozfq7',
        templateParams,
        '4ftFFNrgPmaRO2YAE'
      );
  
      toast({
        title: "Message envoyé !",
        description: "Nous vous répondrons dans les plus brefs délais.",
      });
  
      reset();
    } catch (error) {
      console.error("Erreur EmailJS :", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="relative py-16 sm:py-24 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#685AFF]/10 to-black" />
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(104,90,255,0.1),transparent_50%)]" />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ 
            opacity: [0.5, 0.3, 0.5],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(104,90,255,0.15),transparent_40%)]"
        />
      </div>
      
      <div className="container mx-auto px-4 sm:px-6 relative">
        <div className="max-w-4xl mx-auto mb-12 sm:mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-white">
              Contactez-nous
            </h2>
            <p className="text-lg sm:text-xl text-gray-300">
              Prêt à transformer votre entreprise ? Parlons de vos besoins.
            </p>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 max-w-6xl mx-auto">
          {/* Contact Info */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="space-y-6 sm:space-y-8"
          >
            {/* Phone Contact Card */}
            <motion.div 
              className="relative group"
              whileHover={{ scale: 1.02 }}
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-500 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
              
              <div className="relative flex items-center gap-4 bg-black/50 backdrop-blur-sm p-4 sm:p-6 rounded-lg border border-gray-800">
                <div className="w-10 sm:w-12 h-10 sm:h-12 rounded-lg bg-gradient-to-r from-[#685AFF] to-purple-500 p-2 sm:p-3 flex items-center justify-center">
                  <Phone className="w-5 sm:w-6 h-5 sm:h-6 text-white" />
                </div>
                <div>
                  <h3 className="text-base sm:text-lg font-semibold text-white mb-1">Téléphone</h3>
                  <p className="text-sm sm:text-base text-gray-300">+33 (0)1 43 70 44 91</p>
                </div>
              </div>
            </motion.div>

            {/* Scheduling Frame */}
            <motion.div
              className="relative group"
              whileHover={{ scale: 1.02 }}
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
              
              <div className="relative bg-black/50 backdrop-blur-sm p-4 sm:p-6 rounded-lg border border-gray-800">
                <div className="flex items-center gap-4 mb-4">
                  <div className="w-10 sm:w-12 h-10 sm:h-12 rounded-lg bg-gradient-to-r from-[#685AFF] to-purple-600 p-2 sm:p-3 flex items-center justify-center">
                    <Calendar className="w-5 sm:w-6 h-5 sm:h-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold text-white mb-1">Prise de rendez-vous</h3>
                    <p className="text-sm sm:text-base text-gray-300">Planifiez une consultation gratuite</p>
                  </div>
                </div>
                <Button 
                  className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                  size="lg"
                  onClick={handleCalendlyClick}
                >
                  <span>Réserver un créneau</span>
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </Button>
              </div>
            </motion.div>
          </motion.div>

          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="relative group"
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
            
            <div className="relative bg-black/50 backdrop-blur-sm p-4 sm:p-8 rounded-lg border border-gray-800">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 sm:space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-300">Nom</label>
                    <Input
                      {...register('name')}
                      placeholder="Votre nom"
                      className="bg-black/50 border-gray-700 text-gray-100 placeholder:text-gray-500"
                    />
                    {errors.name && (
                      <span className="text-xs text-red-500">{errors.name.message}</span>
                    )}
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-300">Email</label>
                    <Input
                      {...register('email')}
                      type="email"
                      placeholder="votre@email.com"
                      className="bg-black/50 border-gray-700 text-gray-100 placeholder:text-gray-500"
                    />
                    {errors.email && (
                      <span className="text-xs text-red-500">{errors.email.message}</span>
                    )}
                  </div>
                </div>
                
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-300">Sujet</label>
                  <Input
                    {...register('subject')}
                    placeholder="Comment pouvons-nous vous aider ?"
                    className="bg-black/50 border-gray-700 text-gray-100 placeholder:text-gray-500"
                  />
                  {errors.subject && (
                    <span className="text-xs text-red-500">{errors.subject.message}</span>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-300">Message</label>
                  <Textarea
                    {...register('message')}
                    placeholder="Votre message..."
                    className="bg-black/50 border-gray-700 text-gray-100 placeholder:text-gray-500 min-h-[120px] sm:min-h-[150px]"
                  />
                  {errors.message && (
                    <span className="text-xs text-red-500">{errors.message.message}</span>
                  )}
                </div>

                <Button 
                  type="submit"
                  className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                  size="lg"
                  disabled={isSubmitting}
                >
                  <span>{isSubmitting ? 'Envoi en cours...' : 'Envoyer le message'}</span>
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </Button>
              </form>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}