import { useState } from 'react';
import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useTrainingSessions } from '@/store/TrainingSessionsContext';
import { useToast } from '@/hooks/use-toast';
import { Login } from './Login';
import { Plus, Edit2, Trash2, Save, Loader2 } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

const formations = [
  'ChatGPT Essentiel',
  'Midjourney Créatif',
  'IA pour Entrepreneurs'
];

const locations = ['Paris', 'Marseille'];

export function AdminPanel() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editingSession, setEditingSession] = useState<any>(null);
  const { sessions, addSession, updateSession, deleteSession, loading, error } = useTrainingSessions();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isAuthenticated) {
    return <Login onLogin={() => setIsAuthenticated(true)} />;
  }

  const handleAdd = () => {
    const newSession = {
      formation: formations[0],
      date: new Date().toISOString().split('T')[0],
      time: '9h00 - 17h00',
      location: 'Paris',
      maxParticipants: 12,
      spotsLeft: 12,
      price: '900€ HT',
      type: 'individuel'
    };
    setEditingSession(newSession);
  };

  const handleSave = async (session: any) => {
    setIsSubmitting(true);
    try {
      if (session.id) {
        await updateSession(session.id, session);
      } else {
        await addSession(session);
      }
      setEditingSession(null);
      toast({
        title: "Session mise à jour",
        description: "Les modifications ont été enregistrées avec succès.",
      });
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la sauvegarde.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteSession(id);
      toast({
        title: "Session supprimée",
        description: "La session a été supprimée avec succès.",
      });
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression.",
        variant: "destructive"
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 text-[#685AFF] animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <Button 
            onClick={() => window.location.reload()}
            className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white"
          >
            Réessayer
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-6xl mx-auto"
          >
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold text-white">
                Gestion des Sessions
              </h1>
              <Button
                onClick={handleAdd}
                className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white"
              >
                <Plus className="w-4 h-4 mr-2" />
                Nouvelle Session
              </Button>
            </div>

            {editingSession ? (
              <div className="bg-black/50 backdrop-blur-sm border border-gray-800 rounded-lg p-6 mb-8">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Formation</label>
                    <Select
                      value={editingSession.formation}
                      onValueChange={(value) => setEditingSession({
                        ...editingSession,
                        formation: value
                      })}
                    >
                      <SelectTrigger className="bg-black/50 border-gray-700">
                        <SelectValue placeholder="Sélectionnez une formation" />
                      </SelectTrigger>
                      <SelectContent>
                        {formations.map((formation) => (
                          <SelectItem key={formation} value={formation}>
                            {formation}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Date</label>
                    <Input
                      type="date"
                      value={editingSession.date}
                      onChange={e => setEditingSession({
                        ...editingSession,
                        date: e.target.value
                      })}
                      className="bg-black/50 border-gray-700"
                    />
                  </div>
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Ville</label>
                    <Select
                      value={editingSession.location}
                      onValueChange={(value) => setEditingSession({
                        ...editingSession,
                        location: value
                      })}
                    >
                      <SelectTrigger className="bg-black/50 border-gray-700">
                        <SelectValue placeholder="Sélectionnez une ville" />
                      </SelectTrigger>
                      <SelectContent>
                        {locations.map((location) => (
                          <SelectItem key={location} value={location}>
                            {location}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Places disponibles</label>
                    <Input
                      type="number"
                      value={editingSession.spotsLeft}
                      onChange={e => setEditingSession({
                        ...editingSession,
                        spotsLeft: parseInt(e.target.value)
                      })}
                      className="bg-black/50 border-gray-700"
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <Button
                    variant="outline"
                    onClick={() => setEditingSession(null)}
                  >
                    Annuler
                  </Button>
                  <Button
                    className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white"
                    onClick={() => handleSave(editingSession)}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    ) : (
                      <Save className="w-4 h-4 mr-2" />
                    )}
                    Enregistrer
                  </Button>
                </div>
              </div>
            ) : null}

            <div className="bg-black/50 backdrop-blur-sm border border-gray-800 rounded-lg overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="text-white">Formation</TableHead>
                    <TableHead className="text-white">Date</TableHead>
                    <TableHead className="text-white">Ville</TableHead>
                    <TableHead className="text-white text-right">Places disponibles</TableHead>
                    <TableHead className="text-white text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {sessions.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell className="font-medium text-white">
                        {session.formation}
                      </TableCell>
                      <TableCell className="text-gray-300">{session.date}</TableCell>
                      <TableCell className="text-gray-300">{session.location}</TableCell>
                      <TableCell className="text-right text-gray-300">
                        {session.spotsLeft}/{session.maxParticipants}
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end gap-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => setEditingSession(session)}
                          >
                            <Edit2 className="w-4 h-4" />
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => handleDelete(session.id)}
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}