import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Calendar, Users, Building2, Phone, Mail, ArrowRight, CalendarCheck, Brain } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import emailjs from '@emailjs/browser';

const formSchema = z.object({
  firstName: z.string().min(2, { message: "Le prénom est requis et doit contenir au moins 2 caractères" }),
  lastName: z.string().min(2, { message: "Le nom est requis et doit contenir au moins 2 caractères" }),
  email: z.string().email({ message: "L'email est requis et doit être valide" }),
  phone: z.string().min(10, { message: "Le numéro de téléphone est requis et doit être valide" }),
  company: z.string().min(2, { message: "Le nom de l'entreprise est requis" }),
  position: z.string().min(2, { message: "Votre poste est requis" }),
  formation: z.string(),
  message: z.string().optional(),
  date: z.string(),
});

type FormData = z.infer<typeof formSchema>;

export function Booking() {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSession, setSelectedSession] = useState<any>(null);

  useEffect(() => {
    const sessionData = localStorage.getItem('selectedTrainingDate');
    if (sessionData) {
      setSelectedSession(JSON.parse(sessionData));
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      formation: selectedSession?.formation || '',
      date: selectedSession?.date || '',
    }
  });

  useEffect(() => {
    if (selectedSession) {
      setValue('formation', selectedSession.formation);
      setValue('date', selectedSession.date);
    }
  }, [selectedSession, setValue]);

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true);
    try {
      const templateParams = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        company: data.company,
        position: data.position,
        training: selectedSession.formation,
        sessionDate: selectedSession.date,
        message: data.message || 'Aucun message',
      };

      await emailjs.send(
        'service_s0db8bh',
        'template_dmbpg9s',
        templateParams,
        '4ftFFNrgPmaRO2YAE'
      );
      
      toast({
        title: "Inscription envoyée !",
        description: "Nous vous contacterons rapidement pour confirmer votre inscription.",
      });
      
      reset();
      localStorage.removeItem('selectedTrainingDate');
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!selectedSession) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Aucune session sélectionnée</h2>
          <p className="text-gray-400 mb-4">Veuillez sélectionner une session de formation avant de continuer.</p>
          <Button
            onClick={() => window.location.hash = 'training-dates'}
            className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white"
          >
            Voir les sessions disponibles
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          {/* Hero Section */}
          <div className="max-w-4xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Réserver une Formation
              </h1>
              <p className="text-xl text-gray-300">
                Complétez le formulaire ci-dessous pour réserver votre session de formation
              </p>
            </motion.div>
          </div>

          {/* Booking Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto"
          >
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
              
              <div className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 p-8 rounded-lg">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  {/* Personal Information */}
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-white flex items-center gap-2">
                      <Users className="w-5 h-5 text-[#685AFF]" />
                      Informations Personnelles
                    </h3>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="firstName">Prénom *</Label>
                        <Input
                          {...register('firstName')}
                          id="firstName"
                          placeholder="Votre prénom"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.firstName && (
                          <span className="text-xs text-red-500">{errors.firstName.message}</span>
                        )}
                      </div>
                      
                      <div className="space-y-2">
                        <Label htmlFor="lastName">Nom *</Label>
                        <Input
                          {...register('lastName')}
                          id="lastName"
                          placeholder="Votre nom"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.lastName && (
                          <span className="text-xs text-red-500">{errors.lastName.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="email">Email *</Label>
                        <Input
                          {...register('email')}
                          id="email"
                          type="email"
                          placeholder="votre@email.com"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.email && (
                          <span className="text-xs text-red-500">{errors.email.message}</span>
                        )}
                      </div>
                      
                      <div className="space-y-2">
                        <Label htmlFor="phone">Téléphone *</Label>
                        <Input
                          {...register('phone')}
                          id="phone"
                          type="tel"
                          placeholder="Votre numéro"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.phone && (
                          <span className="text-xs text-red-500">{errors.phone.message}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Company Information */}
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-white flex items-center gap-2">
                      <Building2 className="w-5 h-5 text-[#685AFF]" />
                      Informations Professionnelles
                    </h3>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="company">Entreprise *</Label>
                        <Input
                          {...register('company')}
                          id="company"
                          placeholder="Nom de votre entreprise"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.company && (
                          <span className="text-xs text-red-500">{errors.company.message}</span>
                        )}
                      </div>
                      
                      <div className="space-y-2">
                        <Label htmlFor="position">Poste *</Label>
                        <Input
                          {...register('position')}
                          id="position"
                          placeholder="Votre fonction"
                          className="bg-black/50 border-gray-700"
                          required
                        />
                        {errors.position && (
                          <span className="text-xs text-red-500">{errors.position.message}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Formation Details */}
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-white flex items-center gap-2">
                      <Brain className="w-5 h-5 text-[#685AFF]" />
                      Détails de la Formation
                    </h3>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label>Formation Sélectionnée</Label>
                        <Input
                          value={selectedSession.formation}
                          className="bg-black/50 border-gray-700"
                          disabled
                        />
                      </div>
                      
                      <div className="space-y-2">
                        <Label>Date de la Session</Label>
                        <Input
                          value={selectedSession.date}
                          className="bg-black/50 border-gray-700"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="message">Message (optionnel)</Label>
                      <Textarea
                        {...register('message')}
                        id="message"
                        placeholder="Questions ou informations complémentaires..."
                        className="bg-black/50 border-gray-700 min-h-[100px]"
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <Button 
                    type="submit"
                    className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Envoi en cours...' : 'Réserver ma formation'}
                    <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </Button>
                </form>
              </div>
            </div>
          </motion.div>

          {/* Additional Information */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto mt-12"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex items-start gap-3">
                <CalendarCheck className="w-5 h-5 text-[#685AFF] flex-shrink-0 mt-1" />
                <div>
                  <h4 className="text-white font-semibold">Délai de Réponse</h4>
                  <p className="text-gray-400 text-sm">Confirmation sous 24-48h ouvrées</p>
                </div>
              </div>
              
              <div className="flex items-start gap-3">
                <Phone className="w-5 h-5 text-[#685AFF] flex-shrink-0 mt-1" />
                <div>
                  <h4 className="text-white font-semibold">Contact Direct</h4>
                  <p className="text-gray-400 text-sm">01 43 70 44 91</p>
                </div>
              </div>
              
              <div className="flex items-start gap-3">
                <Mail className="w-5 h-5 text-[#685AFF] flex-shrink-0 mt-1" />
                <div>
                  <h4 className="text-white font-semibold">Email</h4>
                  <p className="text-gray-400 text-sm">infos@ai-forward.com</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}