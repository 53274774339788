import { TeamMember } from './types';

export const teamMembers: TeamMember[] = [
  {
    name: "Arnaud Cliquennois",
    title: "CEO & Founder",
    bio: "Pionnier de la formation à l'intelligence artificielle générative en entreprise.",
    image: "https://www.lejournaldesentreprises.com/sites/lejournaldesentreprises.com/files/styles/landscape_web_lg_1x/public/2024-09/Arnaud-Cliquennois-associ-fondateur-chez-AI-For-3519842.jpeg?h=bee587bd&itok=y1ROD7KF",
    role: "expert",
    linkedin: "https://www.linkedin.com/in/arnaudforward/"
  },
  {
    name: "Marion Brunhes",
    title: "COO",
    bio: "Experte en gestion opérationnelle et développement stratégique.",
    image: "https://i.ibb.co/dbM6gdd/1517668068843.jpg",
    role: "expert",
    linkedin: "https://www.linkedin.com/in/marionbrunhesfaure/"
  },
  {
    name: "Frédéric Brunhes",
    title: "CTO",
    bio: "Expert en stratégie cloud et outils d'IA avec plus de 35 ans d'expérience dans la transformation numérique.",
    image: "https://i.ibb.co/M5QSdvD/1612864327305.jpg",
    role: "expert",
    linkedin: "https://www.linkedin.com/in/brunhes/"
  }
];