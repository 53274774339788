import { motion, AnimatePresence } from 'framer-motion';
import { Filter } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface FilterSectionProps {
  levels: string[];
  domains: string[];
  selectedLevel: string;
  selectedDomain: string;
  onLevelChange: (level: string) => void;
  onDomainChange: (domain: string) => void;
  isOpen: boolean;
  onToggle: () => void;
}

export function FilterSection({
  levels,
  domains,
  selectedLevel,
  selectedDomain,
  onLevelChange,
  onDomainChange,
  isOpen,
  onToggle
}: FilterSectionProps) {
  return (
    <div className="max-w-7xl mx-auto mb-8">
      <div className="flex justify-center mb-2">
        <Button
          variant="ghost"
          size="sm"
          className="text-white hover:bg-white/10"
          onClick={onToggle}
        >
          <Filter className={`w-5 h-5 mr-2 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
          Filtres
        </Button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="bg-black/50 backdrop-blur-sm border border-gray-800 rounded-lg p-6">
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <div className="flex-1 space-y-2">
                  <p className="text-sm text-gray-400 text-center">Niveau</p>
                  <div className="flex flex-wrap gap-2 justify-center">
                    {levels.map((level) => (
                      <Button
                        key={level}
                        variant={selectedLevel === level ? "default" : "outline"}
                        className={`${selectedLevel === level ? "bg-[#685AFF] hover:bg-[#685AFF]/90" : "text-white"} text-sm px-3 py-1 h-auto`}
                        onClick={() => onLevelChange(level)}
                      >
                        {level}
                      </Button>
                    ))}
                  </div>
                </div>

                <div className="hidden sm:block w-px bg-gray-800 self-stretch mx-2" />

                <div className="flex-1 space-y-2">
                  <p className="text-sm text-gray-400 text-center">Domaine</p>
                  <div className="flex flex-wrap gap-2 justify-center">
                    {domains.map((domain) => (
                      <Button
                        key={domain}
                        variant={selectedDomain === domain ? "default" : "outline"}
                        className={`${selectedDomain === domain ? "bg-[#685AFF] hover:bg-[#685AFF]/90" : "text-white"} text-sm px-3 py-1 h-auto`}
                        onClick={() => onDomainChange(domain)}
                      >
                        {domain}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}