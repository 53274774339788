import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';

const testimonials = [
  {
    content: "La découverte de toutes les possibilités aujourd'hui avec l'IA, les fonctionnalités, comprendre leur fonctionnement, les échanges enrichissants entre participants et formateurs.",
    author: "K Flavie"
  },
  {
    content: "Cas d'étude en rapport avec notre activité, accessibilité du formateur, échanges avec les autres participants, théorique le matin puis pratique : bon rythme.",
    author: "G Mary"
  },
  {
    content: "Un formateur passionné, soucieux de nous voir ressortir de cette formation en ayant appris des choses utiles à notre métier. Formation impressionnante par toutes les potentialités qui s'offrent à nous, mais aussi rassurante, avec pour fil conducteur l'importance de l'intervention de l'homme et de l'intelligence humaine dans l'utilisation de ces nouveaux outils.",
    author: "B Manon"
  }
];

export function Testimonials() {
  return (
    <section className="w-full py-12 overflow-hidden">
      <div className="max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            Ce qu'ils en pensent
          </h2>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.author}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="relative group"
            >
              {/* Glowing border */}
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-2xl opacity-0 group-hover:opacity-100 transition duration-500 blur" />
              
              {/* Content */}
              <div className="relative h-full bg-gradient-to-b from-[#685AFF]/10 to-black/50 backdrop-blur-sm border border-gray-800/50 p-8 rounded-2xl flex flex-col">
                {/* Quote icon */}
                <div className="absolute -top-4 -left-4 w-8 h-8 bg-[#685AFF] rounded-full flex items-center justify-center">
                  <Quote className="w-4 h-4 text-white" />
                </div>

                {/* Testimonial content */}
                <div className="flex-grow">
                  <p className="text-gray-100 text-lg leading-relaxed italic mt-4">
                    "{testimonial.content}"
                  </p>
                </div>
                
                {/* Author - Now in a separate div at the bottom */}
                <div className="flex items-center justify-end pt-6 mt-auto">
                  <div className="h-px flex-grow bg-gradient-to-r from-transparent to-[#685AFF]/50 mr-4" />
                  <span className="text-[#685AFF] font-medium">
                    {testimonial.author}
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}