import { motion } from 'framer-motion';
import { Clock, Users, ArrowRight } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Formation } from './types';

interface FormationCardProps {
  formation: Formation;
}

export function FormationCard({ formation }: FormationCardProps) {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const hash = formation.href.replace('#', '');
    window.location.hash = hash;
    
    // Scroll to top after hash change
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <motion.div
      onClick={handleClick}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ scale: 1.02 }}
      className="relative group cursor-pointer h-full"
    >
      {/* Glowing border effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#685AFF] to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition duration-500 blur" />
      
      {/* Card content */}
      <Card className="relative bg-black/50 backdrop-blur-sm border border-gray-800/50 overflow-hidden h-full flex flex-col">
        {/* Image container with overlay */}
        <div className="aspect-video relative overflow-hidden">
          <div className="relative w-full h-full transform transition-transform duration-300 group-hover:scale-110">
            <img 
              src={formation.image} 
              alt={formation.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
          </div>
        </div>
        
        <div className="p-6 flex flex-col flex-grow">
          <h3 className="text-xl font-bold text-white mb-4 line-clamp-2">
            {formation.title}
          </h3>
          
          <div className="space-y-2 mb-4">
            <div className="flex items-center text-gray-400">
              <Users className="w-4 h-4 mr-2" />
              <span className="text-sm">{formation.type}</span>
            </div>
            <div className="flex items-center text-gray-400">
              <Clock className="w-4 h-4 mr-2" />
              <span className="text-sm">{formation.duration}</span>
            </div>
            {formation.accessible && (
              <div className="flex items-center text-[#685AFF]">
                <span className="text-sm font-medium">Accessible</span>
              </div>
            )}
          </div>

          {/* Price */}
          <div className="mb-4">
            <span className="text-xl font-bold text-white">{formation.price}</span>
          </div>

          {/* Description */}
          <p className="text-gray-300 text-sm mb-6 line-clamp-2 flex-grow">
            {formation.description}
          </p>

          {/* Level and Domains */}
          <div className="space-y-2 mb-4">
            <div className="flex flex-wrap gap-2">
              <span className="text-sm px-2 py-1 rounded-full bg-[#685AFF]/20 text-[#685AFF]">
                {formation.level}
              </span>
              {formation.domains.map((domain) => (
                <span 
                  key={domain}
                  className="text-sm px-2 py-1 rounded-full bg-gray-800/50 text-gray-300"
                >
                  {domain}
                </span>
              ))}
            </div>
          </div>

          <Button 
            className="w-full bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group mt-auto"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            En savoir plus
            <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
          </Button>
        </div>
      </Card>
    </motion.div>
  );
}