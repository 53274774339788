import { motion } from 'framer-motion';

const stats = [
  { value: '2000+', label: 'Apprenants formés' },
  { value: '96%', label: 'Taux de satisfaction' },
  { value: '40+', label: 'Sessions réalisées' },
];

export function StatsSection() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12"
    >
      {stats.map((stat) => (
        <div key={stat.label} className="text-center">
          <div className="text-4xl font-bold text-white mb-2">{stat.value}</div>
          <div className="text-gray-400">{stat.label}</div>
        </div>
      ))}
    </motion.div>
  );
}