import { motion } from 'framer-motion';
import { NetworkBackground } from './animations/NetworkBackground';
import { Button } from './ui/button';
import { Home, ArrowLeft } from 'lucide-react';

export function NotFound() {
  return (
    <div className="min-h-screen bg-black relative">
      <NetworkBackground />
      
      <div className="relative min-h-screen flex items-center justify-center">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="relative text-center px-4"
        >
          <h1 className="text-9xl font-bold text-white mb-4">404</h1>
          <h2 className="text-2xl font-semibold text-white mb-6">Page introuvable</h2>
          <p className="text-gray-300 mb-8 max-w-md mx-auto">
            La page que vous recherchez n'existe pas ou a été déplacée. Vous pouvez retourner à l'accueil ou revenir à la page précédente.
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button
              onClick={() => window.location.hash = ''}
              className="bg-[#685AFF] hover:bg-[#685AFF]/90 text-white group w-full sm:w-auto"
            >
              <Home className="w-4 h-4 mr-2" />
              Retour à l'accueil
            </Button>
            <Button
              onClick={() => window.history.back()}
              variant="outline"
              className="text-white border-gray-700 hover:bg-white/5 w-full sm:w-auto"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Page précédente
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}