import { motion } from 'framer-motion';
import { NetworkBackground } from '../../animations/NetworkBackground';
import { Mail, Building, Shield, Lock, Cookie, Phone, MapPin } from 'lucide-react';

export function MentionsLegales() {
  return (
    <div>
      <NetworkBackground />
      
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#685AFF]/10 to-transparent" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-12 text-white text-center">
              Mentions Légales
            </h1>

            <div className="prose prose-invert max-w-none space-y-8">
              {/* Éditeur du site */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Building className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Éditeur du site</h2>
                </div>
                <p>Le site AI-Forward.com est édité par :</p>
                <p className="font-semibold">AI-FORWARD</p>
                <ul>
                  <li>Forme juridique : Société par actions simplifiée (SAS) à associé unique</li>
                  <li>RCS : 949 247 480 R.C.S. Soissons</li>
                  <li>Capital social : 5 000 €</li>
                  <li>Adresse du siège social : 7 rue aux Vaches, 02220 Maast-et-Violaine</li>
                  <li>Activités principales : Formation professionnelle continue d'adultes, conseil en stratégie et marketing, et activités connexes.</li>
                  <li>Président : Arnaud Cliquennois</li>
                  <li>Contact : infos@ai-forward.com</li>
                </ul>
              </motion.div>

              {/* Hébergement du site */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Building className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Hébergement du site</h2>
                </div>
                <p>Le site est hébergé par :</p>
                <ul>
                  <li>[Nom de l'hébergeur]</li>
                  <li>Adresse : [Adresse complète]</li>
                  <li>Contact : [E-mail ou téléphone]</li>
                </ul>
              </motion.div>

              {/* Propriété Intellectuelle */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Shield className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Propriété Intellectuelle</h2>
                </div>
                <p>Tous les contenus présents sur le site AI-Forward.com (textes, images, logos, vidéos, etc.) sont protégés par les lois sur la propriété intellectuelle. Toute reproduction, modification ou distribution sans autorisation expresse est strictement interdite.</p>
              </motion.div>

              {/* Protection des Données Personnelles et Politique de Confidentialité */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Lock className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Protection des Données Personnelles et Politique de Confidentialité</h2>
                </div>

                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-semibold mb-2">1. Responsable du traitement :</h3>
                    <p>AI-FORWARD</p>
                    <p>E-mail : support@ai-forward.com</p>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">2. Données collectées :</h3>
                    <p>Les données personnelles collectées incluent :</p>
                    <ul>
                      <li>Nom, prénom</li>
                      <li>Adresse e-mail</li>
                      <li>Téléphone</li>
                      <li>Données de connexion (adresses IP, cookies)</li>
                      <li>Informations sur les formations suivies</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">3. Finalités du traitement :</h3>
                    <p>Les données personnelles collectées sont utilisées pour :</p>
                    <ul>
                      <li>La gestion des inscriptions et des formations.</li>
                      <li>L'envoi de newsletters et d'informations commerciales.</li>
                      <li>Le suivi pédagogique et les évaluations des apprenants.</li>
                      <li>L'amélioration des services proposés.</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">4. Base légale :</h3>
                    <p>Le traitement des données repose sur :</p>
                    <ul>
                      <li>Le consentement des utilisateurs.</li>
                      <li>L'exécution de contrats liés aux formations.</li>
                      <li>Les obligations légales applicables à l'entreprise.</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">5. Conservation des données :</h3>
                    <p>Les données sont conservées :</p>
                    <ul>
                      <li>Pendant 3 ans à compter de la fin de la relation commerciale (informations commerciales).</li>
                      <li>Pour une durée légale de conservation en cas d'obligation réglementaire.</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">6. Droits des utilisateurs :</h3>
                    <p>Conformément au RGPD, vous disposez des droits suivants :</p>
                    <ul>
                      <li>Droit d'accès, de rectification et d'effacement de vos données.</li>
                      <li>Droit à la limitation et à l'opposition au traitement.</li>
                      <li>Droit à la portabilité des données.</li>
                    </ul>
                    <p>Pour exercer ces droits, contactez-nous à support@ai-forward.com.</p>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">7. Cookies :</h3>
                    <p>Le site utilise des cookies pour améliorer l'expérience utilisateur et mesurer l'audience. Vous pouvez configurer vos préférences via l'outil de gestion des cookies disponible sur le site.</p>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">8. Sécurité des données :</h3>
                    <p>Des mesures techniques et organisationnelles appropriées sont mises en place pour protéger vos données contre tout accès non autorisé, perte ou altération.</p>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-2">9. Transferts de données :</h3>
                    <p>Aucun transfert de données personnelles en dehors de l'Union européenne n'est effectué.</p>
                  </div>
                </div>
              </motion.div>

              {/* Certification Qualiopi */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Shield className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Certification Qualiopi</h2>
                </div>
                <p>AI-FORWARD est certifié Qualiopi, attestant de la qualité des processus pour les actions de formation professionnelle continue.</p>
              </motion.div>

              {/* Conditions Générales de Vente */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Cookie className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Conditions Générales de Vente</h2>
                </div>
                <p>Les CGV d'AI-FORWARD régissent les relations commerciales entre l'organisme et ses clients. Elles sont disponibles sur demande ou dans la section dédiée du site.</p>
              </motion.div>

              {/* Responsabilité */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Shield className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Responsabilité</h2>
                </div>
                <p>AI-FORWARD décline toute responsabilité pour les dommages directs ou indirects liés à l'utilisation du site ou des services proposés.</p>
              </motion.div>

              {/* Contact */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Mail className="w-6 h-6 text-[#685AFF]" />
                  <h2 className="text-2xl font-bold text-white m-0">Contact</h2>
                </div>
                <p>Pour toute question ou réclamation :</p>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <Mail className="w-4 h-4 text-[#685AFF]" />
                    <span>Adresse e-mail : infos@ai-forward.com</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <MapPin className="w-4 h-4 text-[#685AFF]" />
                    <span>Adresse postale : 11 Bis rue des primevères 92500 Rueil-Malmaison</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}